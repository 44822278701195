import { Model } from '../shared/charts/types'

type KeyValueResult = {
	[key: string]: { [year: string]: number | null }
}

function transformToKeyValue({ labels, datasets }: Model): KeyValueResult {
	return datasets.reduce((acc, dataset) => {
		const { id, values } = dataset
		acc[id] = labels.reduce(
			(yearAcc, year, index) => {
				yearAcc[year] = values[index] !== undefined ? values[index] : null
				return yearAcc
			},
			{} as { [year: string]: number | null },
		)
		return acc
	}, {} as KeyValueResult)
}

export { transformToKeyValue }
