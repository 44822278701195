import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { LadElderlyBedShortagesForecastChart } from '../../../../charts/ElderlyBedShortagesForecastChart/LadElderlyBedShortagesForecastChart'
import { LocalAuthorityByLadCodeQuery as LaQueryResponseType } from '../../../../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { LocalAuthorityByLadCodeQuery as LaQueryRequestType } from '../../../../graphql/queries/LocalAuthorityByLad'
import { LadElderlyCareQuickFacts } from '../../../../shared/LadElderlyCareQuickFacts'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../shared/layout/Space'
import { Loading } from '../../../../shared/Loading'
import { Panel } from '../../../../shared/Panel'
import { PageTitle, ParagraphBold, SubtitleBox } from '../../../../shared/Text'

interface ElderlyCareMarketProps {
	ladCode: string
}

const ElderlyCareMarket: React.FC<ElderlyCareMarketProps> = ({ ladCode }) => {
	const { localAuthorityByLadCode: la } = useLazyLoadQuery<LaQueryResponseType>(LaQueryRequestType, { ladCode })
	const { name } = la
	return (
		<Container size="fluid">
			<Row>
				<Col count={4}>
					<Panel height={'auto'}>
						<SubtitleBox>Elderly Care Market</SubtitleBox>
						<PageTitle> {name} </PageTitle>
						<ParagraphBold>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
							et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
							sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
						</ParagraphBold>
					</Panel>
					<VerticalSpace size="sm" />
					<LadElderlyCareQuickFacts ladCode={ladCode} />
				</Col>
				<Col count={8}>
					<Panel height={'auto'}>
						<VerticalSpace size="sm" />
						<LadElderlyBedShortagesForecastChart ladCode={ladCode} />
						<VerticalSpace size="sm" />
					</Panel>
					<VerticalSpace size="md" />
					<Panel height={'auto'}>Elderly Bed Supply Rate Comparison:Coming Soon</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
		</Container>
	)
}

const Suspended: React.FC<ElderlyCareMarketProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<ElderlyCareMarket ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyCareMarket }
