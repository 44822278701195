import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import { PopulationByLadCodeQuery as QueryResponseType } from '../../graphql/queries/__generated__/PopulationByLadCodeQuery.graphql'
import { PopulationByLadCodeQuery as QueryRequestType } from '../../graphql/queries/PopulationByLad'
import { Model } from '../../shared/charts/types'
import { PopulationGrowthByAgeGroupChart } from './PopulationGrowthByAgeGroupChart'

interface LadPopulationGrowthByAgeGroupChartProps {
	ladCode: string
}

const LadPopulationGrowthByAgeGroupChart: React.FC<LadPopulationGrowthByAgeGroupChartProps> = ({ ladCode }) => {
	const { populationByLadCode: pop } = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		ladCode,
		startYear: currentYear,
		rangeSize: 20,
		minAge: 0,
		futureOffset: 5,
		includeIntermediates: false,
	})

	const labels = pop.geographies[0].ageRanges.map((ageRange) => ageRange.ageRange)
	const currentPopulations = pop.geographies[0].ageRanges.map((ageRange) => ageRange.values[0])
	const futurePopulations = pop.geographies[0].ageRanges.map((ageRange) => ageRange.values[ageRange.values.length - 1])

	const model: Model = {
		labels,
		datasets: [
			{
				id: `${pop.years[0]}`,
				values: currentPopulations,
			},
			{
				id: `${pop.years[1]}`,
				values: futurePopulations,
			},
		],
	}

	return <PopulationGrowthByAgeGroupChart model={model} />
}

export { LadPopulationGrowthByAgeGroupChart }
