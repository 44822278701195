import './textPlaceholder.css'

import React from 'react'

interface TextPlaceholderProps {
	height?: string | number
}

const TextPlaceholder: React.FC<TextPlaceholderProps> = ({ height = '80px' }) => (
	<div className="text-placeholder" style={{ height }}></div>
)

export { TextPlaceholder }
