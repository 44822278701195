import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { CareFeesByLadCodeQuery as Response } from '../../graphql/queries/__generated__/CareFeesByLadCodeQuery.graphql'
import { CareFeesByLadCode as Request } from '../../graphql/queries/CareFeesByLad'
import { Loading } from '../../shared/Loading'
import { formatCurrency } from '../../utils/formatNumber'
import { QuickFact } from '../QuickFacts'
import { CareFeesQuickFactFragment$key } from './__generated__/CareFeesQuickFactFragment.graphql'
import { CareFeesQuickFactFragment } from './CareFeesQuickFactFragment'

interface CareFeeQuickFactProps {
	ladCode: string
}

const CareFeeByLad: React.FC<CareFeeQuickFactProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<Response>(Request, { ladCode })
	const { labels, datasets } = useFragment<CareFeesQuickFactFragment$key>(
		CareFeesQuickFactFragment,
		data.careFeeRatesByLadCode,
	)

	const index = labels.findIndex((label) => label === 'avg_hourly')
	const avgHourly = datasets[0].values[index]
	const nationalAvgHourly = datasets[1].values[index]

	return (
		<QuickFact
			heading="Average Care Home Fee"
			value={formatCurrency(avgHourly)}
			comparison={formatCurrency(nationalAvgHourly)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<CareFeeQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading height={75} />}>
			<CareFeeByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as CareFeeByLad }
