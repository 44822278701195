import React from 'react'

import { BarChart } from '../../shared/charts/BarChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface HousingYearsToBuyChartProps {
	model: Model
}

const HousingYearsToBuyChart: React.FC<HousingYearsToBuyChartProps> = ({ model }) => {
	return (
		<>
			<Subtitle>Years-to-Buy Ratio</Subtitle>
			<Paragraph>
				Comparison of average house prices to gross disposable household income (GDHI), showing how many years it would
				take to buy a house with a 100% saving rate. <br /> This metric provides a nuanced view of the housing market
				and living situation by factoring in local income levels, offering insights into housing affordability across
				the catchment area, region, and England.
			</Paragraph>
			<VerticalSpace size="sm" />
			<BarChart horizontal={false} stacked={false} model={model} showLabels={true} alpha={0.9} aspectRatio={2} />
		</>
	)
}

export { HousingYearsToBuyChart }
