import { PopulationBracketsByLadCodeQuery$data as PopulationQueryDataType$Data } from '../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import { StatXDementiaAACountQuery$data as DementiaQueryDataType$Data } from '../graphql/queries/__generated__/StatXDementiaAACountQuery.graphql'

export function calculateElderlyWithDementiaShare(
	populationData: PopulationQueryDataType$Data | null | undefined,
	dementiaData: DementiaQueryDataType$Data | null | undefined,
): { labels: string[]; datasets: { id: string; values: (number | null)[] }[] } {
	// Early return if populationData is missing
	if (!populationData || !populationData.populationBracketsByLadCode) {
		return { labels: [], datasets: [] }
	}

	// Early return if dementiaData is missing
	if (
		!dementiaData ||
		!dementiaData.statx ||
		!Array.isArray(dementiaData.statx.rows) ||
		!Array.isArray(dementiaData.statx.headers)
	) {
		return { labels: [], datasets: [] }
	}

	const dementiaHeaders = dementiaData.statx.headers
	const dateIndex = dementiaHeaders.indexOf('Quarter')
	const geographyNameIndex = dementiaHeaders.indexOf('National - Regional - LA - OAs')
	const valueIndex = dementiaHeaders.indexOf('AA (entitled) - 2011 Geographies')

	// Return empty if required headers are missing
	if (dateIndex === -1 || geographyNameIndex === -1 || valueIndex === -1) {
		return { labels: [], datasets: [] }
	}

	// Define the years you're interested in
	const yearsRange = [2020, 2021, 2022, 2023, 2024]
	const yearsSet = new Set(yearsRange)

	// Extract years from dementia data
	const dementiaYearsSet = new Set<number>(
		dementiaData.statx.rows.map((row) => {
			const quarter = row[dateIndex] as string
			const year = 2000 + parseInt(quarter.split('-')[1], 10)
			return year
		}),
	)

	// Extract years from population data
	const populationYearsSet = new Set<number>(
		populationData.populationBracketsByLadCode.years.map((yearData) => yearData.year),
	)

	// Determine the common years present in both datasets
	const commonYears = yearsRange.filter(
		(year) => yearsSet.has(year) && dementiaYearsSet.has(year) && populationYearsSet.has(year),
	)

	if (commonYears.length === 0) {
		return { labels: [], datasets: [] }
	}

	// Build the population data map using geoName
	const populationDataMap: { [geoName: string]: { [year: number]: number } } = {}

	populationData.populationBracketsByLadCode.years.forEach((yearData) => {
		if (yearsSet.has(yearData.year)) {
			yearData.geographies.forEach((geo) => {
				const geoName = geo.geoName
				if (!populationDataMap[geoName]) {
					populationDataMap[geoName] = {}
				}
				// Only include if geo.total is a valid number
				if (typeof geo.total === 'number') {
					populationDataMap[geoName][yearData.year] = geo.total
				}
			})
		}
	})

	// Build the dementia data map using geoName
	const dementiaDataMap: { [geoName: string]: { [year: number]: number } } = {}

	dementiaData.statx.rows.forEach((row) => {
		const quarter = row[dateIndex] as string
		const year = 2000 + parseInt(quarter.split('-')[1], 10)
		if (yearsSet.has(year)) {
			const geoName = row[geographyNameIndex] as string
			const value = parseFloat(row[valueIndex] as string)
			if (!dementiaDataMap[geoName]) {
				dementiaDataMap[geoName] = {}
			}
			dementiaDataMap[geoName][year] = value
		}
	})

	// Get the list of unique geographies
	const geographies = Array.from(new Set([...Object.keys(populationDataMap), ...Object.keys(dementiaDataMap)]))

	// Compute the prevalence rates
	const datasets = geographies.map((geoName) => {
		const values = commonYears.map((year) => {
			const populationTotal = populationDataMap[geoName]?.[year]
			const dementiaTotal = dementiaDataMap[geoName]?.[year]
			if (populationTotal != null && dementiaTotal != null && populationTotal !== 0) {
				return parseFloat(((dementiaTotal / populationTotal) * 100).toFixed(2))
			} else {
				return null
			}
		})
		return { id: geoName, values }
	})

	const labels = commonYears.map((year) => year.toString())

	return { labels, datasets }
}
