import React from 'react'

import { PieChart } from '../../shared/charts/PieChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface TenureTypePieChartProps {
	model: Model
}

const TenureTypePieChart: React.FC<TenureTypePieChartProps> = ({ model }) => {
	return (
		<>
			<Subtitle>Tenure of Households</Subtitle>
			<Paragraph>Share of Tenure Types | Census 2021</Paragraph>
			<VerticalSpace size="sm" />
			<PieChart model={model} aspectRatio={1} />
		</>
	)
}

export { TenureTypePieChart }
