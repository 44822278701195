import { AAAwardTypesByLadCodeQuery$data } from '../graphql/queries/__generated__/AAAwardTypesByLadCodeQuery.graphql'

function calculateBedDemandData(
	aaData: AAAwardTypesByLadCodeQuery$data['aaAwardTypesByLadCode'],
	yearsOfInterest: number[],
): { [year: number]: number } {
	// Collect data for years of interest
	return yearsOfInterest.reduce(
		(acc, year) => {
			const index = aaData.labels.indexOf(`${year}`)
			if (index >= 0) {
				acc[year] = aaData.higherRate[0].values[index]
			} else {
				acc[year] = 0
			}
			return acc
		},
		{} as { [year: number]: number },
	)
}

export { calculateBedDemandData }
