/**
 * @generated SignedSource<<aee7088b2defe01f08863c23941da6f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OpenAiVersions = "V3" | "V4" | "%future added value";
export type PromptQuery$variables = {
  content: string;
  key: string;
  temperature?: number | null | undefined;
  version: OpenAiVersions;
};
export type PromptQuery$data = {
  readonly prompt: {
    readonly response: string;
    readonly version: string;
  };
};
export type PromptQuery = {
  response: PromptQuery$data;
  variables: PromptQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "content"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "key"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "temperature"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "version"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "content",
        "variableName": "content"
      },
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      },
      {
        "kind": "Variable",
        "name": "temperature",
        "variableName": "temperature"
      },
      {
        "kind": "Variable",
        "name": "version",
        "variableName": "version"
      }
    ],
    "concreteType": "OpenAiChatResponse",
    "kind": "LinkedField",
    "name": "prompt",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "response",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PromptQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PromptQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "ff9b98f0651d0581a2ea77d8b1f1ce14",
    "id": null,
    "metadata": {},
    "name": "PromptQuery",
    "operationKind": "query",
    "text": "query PromptQuery(\n  $content: String!\n  $key: String!\n  $version: OpenAiVersions!\n  $temperature: Float\n) {\n  prompt(content: $content, key: $key, version: $version, temperature: $temperature) {\n    response\n    version\n  }\n}\n"
  }
};
})();

(node as any).hash = "c167fca5f0d231a2e05f68a0859debe4";

export default node;
