import React from 'react'
import * as i from 'react-feather'
import { useLazyLoadQuery } from 'react-relay'
import { useParams } from 'react-router-dom'

import { OperatorBySiteIdQuery as OperatorResponseQuery } from '../../graphql/queries/__generated__/OperatorBySiteIdQuery.graphql'
import { SiteByIdQuery as SiteResponseQuery } from '../../graphql/queries/__generated__/SiteByIdQuery.graphql'
import { OperatorBySiteIdQuery as OperatorRequestQuery } from '../../graphql/queries/OperatorBySiteId'
import { SiteByIdQuery as SiteRequestQuery } from '../../graphql/queries/SiteById'
import { Page } from '../../layout/page/Page'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { MapFeatures, Marker } from '../../shared/map/MapFeatures'
import { Panel } from '../../shared/Panel'
import { PageTitle, Subtitle } from '../../shared/Text'

type RouteParams = { id: string }

const Site: React.FC = () => {
	const { id = '' } = useParams<RouteParams>() // Get lad from the route

	const { siteById: siteData } = useLazyLoadQuery<SiteResponseQuery>(SiteRequestQuery, { id })
	const { operatorBySiteId: operatorData } = useLazyLoadQuery<OperatorResponseQuery>(OperatorRequestQuery, { id })

	const location: Marker = {
		location: siteData.location ? JSON.parse(siteData.location) : {},
		name: siteData.name,
		id: siteData.id,
	}

	return (
		<Page title={`Care Home - ${siteData.name}`}>
			<Container>
				<Row>
					<div className="col-12">
						<PageTitle>{siteData.name}</PageTitle>
					</div>
				</Row>
				<Row>
					<div className="col-12">
						<VerticalSpace size="lg" />
						<Panel padding={8} height="auto">
							<MapFeatures features={[]} locations={[location]} height="40vh" />
						</Panel>
						<VerticalSpace size="lg" />
					</div>
				</Row>
				<Row>
					<Col count={6}>
						<Panel>
							<Subtitle>Site Details</Subtitle>
							<div className="list-group-flush">
								<div className="list-group-item">
									<i.Home /> Care Home: {siteData.careHome}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Constituency: {siteData.constituency}
								</div>
								<div className="list-group-item">
									<i.Hash /> CQC Location ID: {siteData.cqcLocationId}
								</div>
								<div className="list-group-item">
									<i.Hash /> CQC Provider ID: {siteData.cqcProviderId}
								</div>
								<div className="list-group-item">
									<i.Calendar /> Created At: {new Date(siteData.createdAt).toLocaleString()}
								</div>
								<div className="list-group-item">
									<i.AlertCircle /> Dormancy: {siteData.dormancy}
								</div>
								<div className="list-group-item">
									<i.Briefcase /> Inspection Directorate: {siteData.inspectionDirectorate}
								</div>
								<div className="list-group-item">
									<i.Calendar /> Last Inspection Date: {new Date(siteData.lastInspectionDate).toLocaleDateString()}
								</div>
								<div className="list-group-item">
									<i.Calendar /> Last Report Publication Date:{' '}
									{new Date(siteData.lastReportPublicationDate).toLocaleDateString()}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Local Authority: {siteData.localAuthority}
								</div>
								<div className="list-group-item">
									<i.Phone /> Main Phone Number: {siteData.mainPhoneNumber}
								</div>
								<div className="list-group-item">
									<i.Hash /> Number of Beds: {siteData.numberOfBeds}
								</div>
								<div className="list-group-item">
									<i.Hash /> ODS Code: {siteData.odsCode}
								</div>
								<div className="list-group-item">
									<i.Hash /> ONSPD CCG Code: {siteData.onspdCcgCode}
								</div>
								<div className="list-group-item">
									<i.Hash /> ONSPD CCG Name: {siteData.onspdCcgName}
								</div>
								<div className="list-group-item">
									<i.Hash /> ONSPD ICB Code: {siteData.onspdIcbCode}
								</div>
								<div className="list-group-item">
									<i.Hash /> ONSPD ICB Name: {siteData.onspdIcbName}
								</div>
								<div className="list-group-item">
									<i.Compass /> Latitude: {siteData.onspdLatitude}
								</div>
								<div className="list-group-item">
									<i.Compass /> Longitude: {siteData.onspdLongitude}
								</div>
								<div className="list-group-item">
									<i.Type /> Organisation Type: {siteData.organisationType}
								</div>
								<div className="list-group-item">
									<i.Phone /> Phone Number: {siteData.phoneNumber}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Address Line 1: {siteData.postalAddressLine1}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Address Town/City: {siteData.postalAddressTownCity}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Code: {siteData.postalCode}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Region: {siteData.region}
								</div>
								<div className="list-group-item">
									<i.Calendar /> Registration Date: {new Date(siteData.registrationDate).toLocaleDateString()}
								</div>
								<div className="list-group-item">
									<i.CheckCircle /> Registration Status: {siteData.registrationStatus}
								</div>
								<div className="list-group-item">
									<i.List /> Service Types: {siteData.serviceTypes}
								</div>
								<div className="list-group-item">
									<i.Users /> Services Providers: {siteData.servicesProviders}
								</div>
								<div className="list-group-item">
									<i.Database /> Source: {siteData.source}
								</div>
								<div className="list-group-item">
									<i.Type /> Type: {siteData.type}
								</div>
								<div className="list-group-item">
									<i.Calendar /> Updated At: {new Date(siteData.updatedAt).toLocaleString()}
								</div>
								<div className="list-group-item">
									<i.Hash /> UPRN: {siteData.uprn}
								</div>
								<div className="list-group-item">
									<i.Globe /> Website: {siteData.website}
								</div>
							</div>
						</Panel>
					</Col>

					<Col count={6}>
						<Panel>
							<Subtitle>Operator Details</Subtitle>
							<div className="list-group-flush">
								<div className="list-group-item">
									<i.User /> Name: {operatorData.name}
								</div>
								<div className="list-group-item">
									<i.Tag /> Brand Name: {operatorData.brandName}
								</div>
								<div className="list-group-item">
									<i.Hash /> Companies House Number: {operatorData.companiesHouseNumber}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Constituency: {operatorData.constituency}
								</div>
								<div className="list-group-item">
									<i.Hash /> CQC Provider ID: {operatorData.cqcProviderId}
								</div>
								<div className="list-group-item">
									<i.Calendar /> Created At: {new Date(operatorData.createdAt).toLocaleString()}
								</div>
								<div className="list-group-item">
									<i.Briefcase /> Inspection Directorate: {operatorData.inspectionDirectorate}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Local Authority: {operatorData.localAuthority}
								</div>
								<div className="list-group-item">
									<i.Phone /> Main Phone Number: {operatorData.mainPhoneNumber}
								</div>
								<div className="list-group-item">
									<i.Hash /> ODS Code: {operatorData.odsCode}
								</div>
								<div className="list-group-item">
									<i.Hash /> ONSPD ICB Code: {operatorData.onspdIcbCode}
								</div>
								<div className="list-group-item">
									<i.Hash /> ONSPD ICB Name: {operatorData.onspdIcbName}
								</div>
								<div className="list-group-item">
									<i.Compass /> Latitude: {operatorData.onspdLatitude}
								</div>
								<div className="list-group-item">
									<i.Compass /> Longitude: {operatorData.onspdLongitude}
								</div>
								<div className="list-group-item">
									<i.Type /> Organisation Type: {operatorData.organisationType}
								</div>
								<div className="list-group-item">
									<i.Briefcase /> Ownership Type: {operatorData.ownershipType}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Address Line 1: {operatorData.postalAddressLine1}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Address Line 2: {operatorData.postalAddressLine2}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Address Town/City: {operatorData.postalAddressTownCity}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Code: {operatorData.postalCode}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Region: {operatorData.region}
								</div>
								<div className="list-group-item">
									<i.Calendar /> Registration Date: {new Date(operatorData.registrationDate).toLocaleDateString()}
								</div>
								<div className="list-group-item">
									<i.CheckCircle /> Registration Status: {operatorData.registrationStatus}
								</div>
								<div className="list-group-item">
									<i.Database /> Source: {operatorData.source}
								</div>
								<div className="list-group-item">
									<i.Type /> Type: {operatorData.type}
								</div>
								<div className="list-group-item">
									<i.Calendar /> Updated At: {new Date(operatorData.updatedAt).toLocaleString()}
								</div>
								<div className="list-group-item">
									<i.Globe /> Website:
									<a href={`http://${operatorData.website}`} target="_blank" rel="noopener noreferrer">
										{operatorData.website}
									</a>
								</div>
							</div>
						</Panel>
					</Col>
				</Row>
			</Container>
		</Page>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Site />
		</React.Suspense>
	)
}

export { Suspended as Site }
