import React from 'react'

import { LineChart } from '../../shared/charts/LineChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface ElderlyPopulationShareChartProps {
	model: Model
}

const ElderlyPopulationShareChart: React.FC<ElderlyPopulationShareChartProps> = ({ model }) => {
	return (
		<>
			<Subtitle>Elderly Population Share</Subtitle>
			<Paragraph>Historic and 10 year forecast | Based on Census 2021 Data</Paragraph>
			<VerticalSpace size="sm" />
			<LineChart model={model} prefix="" suffix="%" dataLabelDisplayOption="showAll" aspectRatio={1} />
		</>
	)
}

export { ElderlyPopulationShareChart }
