import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import {
	GrossDisposableHouseholdIncomesByCaQuery as GDHIResponse,
	RadiusValues,
} from '../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByCaQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesRequest } from '../../graphql/queries/GeographiesByCodes'
import { GrossDisposableHouseholdIncomesByCaQuery as GDHIRequest } from '../../graphql/queries/GrossDisposableHouseholdIncomesByCa'
import { Model } from '../../shared/charts/types'
import { GDHIChart } from './GDHIChart'

interface CaGDHIChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaGDHIChart: React.FC<CaGDHIChartProps> = ({ oaCode, radius }) => {
	const { grossDisposableHouseholdIncomesByCa: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<GDHIResponse>(
		GDHIRequest,
		{
			oaCode,
			radius,
		},
	)

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodesRequest, {
		codes,
	})

	const mappedDatasets = datasets.map((d) => ({
		id: geos.find((g) => g.code === d.code)?.name || 'Catchment Area',
		values: [...d.values],
	}))

	const model: Model = {
		labels: [...labels],
		datasets: mappedDatasets,
	}

	return <GDHIChart model={model} />
}
export { CaGDHIChart }
