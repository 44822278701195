import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import { PopulationByLadCodeQuery as Response } from '../../graphql/queries/__generated__/PopulationByLadCodeQuery.graphql'
import { PopulationByLadCodeQuery as Request } from '../../graphql/queries/PopulationByLad'
import { Loading } from '../../shared/Loading'
import { formatNumber } from '../../utils/formatNumber'
import { QuickFact } from '../QuickFacts'

interface PopulationQuickFactProps {
	ladCode: string
}

const PopulationByLad: React.FC<PopulationQuickFactProps> = ({ ladCode }) => {
	const { populationByLadCode: pop } = useLazyLoadQuery<Response>(Request, {
		ladCode,
		startYear: currentYear,
		futureOffset: 0,
		minAge: 0,
		rangeSize: 100,
		includeIntermediates: false,
	})

	const lad = pop.geographies[0].ageRanges[0].values[0]
	const national = pop.geographies[2].ageRanges[0].values[0]

	return (
		<QuickFact heading="Population" value={formatNumber(lad)} comparison={formatNumber(national)} isComparable={true} />
	)
}

const Suspended: React.FC<PopulationQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading height={75} />}>
			<PopulationByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as PopulationByLad }
