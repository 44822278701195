import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { LocalAuthorityByLadCodeQuery as LaQueryResponseType } from '../../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { OperatorsByLadCodeQuery as OperatorsResponse } from '../../graphql/queries/__generated__/OperatorsByLadCodeQuery.graphql'
import { LocalAuthorityByLadCodeQuery as LaQueryRequestType } from '../../graphql/queries/LocalAuthorityByLad'
import { OperatorsByLadCodeQuery as OperatorsRequest } from '../../graphql/queries/OperatorsByLadCode'
import { OperatorsTableFragment$key } from './__generated__/OperatorsTableFragment.graphql'
import { OperatorsTable } from './OperatorsTable'
import { OperatorsTableFragment } from './OperatorsTableFragment'

interface LaOperatorsTableProps {
	ladCode: string
}

const LaOperatorsTable: React.FC<LaOperatorsTableProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<OperatorsResponse>(OperatorsRequest, { ladCode })
	const { localAuthorityByLadCode: LaName } = useLazyLoadQuery<LaQueryResponseType>(LaQueryRequestType, { ladCode })
	const { name } = LaName

	const operatorData = data.operatorsByLadCode || []

	const operators = operatorData.map((o: OperatorsTableFragment$key) => {
		return useFragment(OperatorsTableFragment, o)
	})

	return <OperatorsTable operators={operators} code={ladCode} name={name} />
}

export { LaOperatorsTable }
