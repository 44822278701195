import { GeographiesByCodesQuery$data } from '../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { GrossDisposableHouseholdIncomesByCaQuery$data } from '../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByCaQuery.graphql'
import { GrossDisposableHouseholdIncomesByLadCodeQuery$data } from '../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByLadCodeQuery.graphql'
import { HousePricesByCaQuery$data } from '../graphql/queries/__generated__/HousePricesByCaQuery.graphql'
import { HousePricesByLadCodeQuery$data } from '../graphql/queries/__generated__/HousePricesByLadCodeQuery.graphql'

type HP = HousePricesByCaQuery$data['housePricesByCa'] | HousePricesByLadCodeQuery$data['housePricesByLadCode']
type GDHI =
	| GrossDisposableHouseholdIncomesByCaQuery$data['grossDisposableHouseholdIncomesByCa']
	| GrossDisposableHouseholdIncomesByLadCodeQuery$data['grossDisposableHouseholdIncomesByLadCode']
type GEO = GeographiesByCodesQuery$data['geographiesByCodes']

const calculateYearsToBuy = (hp: HP, gdhi: GDHI, geos: GEO) => {
	// Find common years
	const commonYears = hp.labels.filter((year) => gdhi.labels.includes(year))

	// Map indexes for the common years
	const hpYearIndexes = commonYears.map((year) => hp.labels.indexOf(year))
	const gdhiYearIndexes = commonYears.map((year) => gdhi.labels.indexOf(year))

	// Initialize datasets as a map for faster lookups
	const datasetsMap = new Map()

	hp.datasets.forEach((hpData) => {
		const gdhiData = gdhi.datasets.find((g) => g.code === hpData.code)

		if (!gdhiData) return

		commonYears.forEach((_, index) => {
			const hpValue = hpData.values[hpYearIndexes[index]]
			const gdhiValue = gdhiData.values[gdhiYearIndexes[index]] || 1

			const yearsToBuy = parseFloat((hpValue / gdhiValue).toFixed(2))

			if (!datasetsMap.has(hpData.code)) {
				datasetsMap.set(hpData.code, { code: hpData.code, values: [] })
			}

			datasetsMap.get(hpData.code).values.push(yearsToBuy)
		})
	})

	return {
		labels: commonYears,
		datasets: Array.from(datasetsMap.values()).map((d: any) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}
}

export { calculateYearsToBuy }
