/**
 * @generated SignedSource<<124eb051963a9d3549ee246e347e3983>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GrossDisposableHouseholdIncomesByLadCodeQuery$variables = {
  ladCode: string;
};
export type GrossDisposableHouseholdIncomesByLadCodeQuery$data = {
  readonly grossDisposableHouseholdIncomesByLadCode: {
    readonly datasets: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly labels: ReadonlyArray<string>;
  };
};
export type GrossDisposableHouseholdIncomesByLadCodeQuery = {
  response: GrossDisposableHouseholdIncomesByLadCodeQuery$data;
  variables: GrossDisposableHouseholdIncomesByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ladCode",
        "variableName": "ladCode"
      }
    ],
    "concreteType": "GrossDisposableHouseholdIncomes",
    "kind": "LinkedField",
    "name": "grossDisposableHouseholdIncomesByLadCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labels",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GrossDisposableHouseholdIncomeData",
        "kind": "LinkedField",
        "name": "datasets",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "values",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GrossDisposableHouseholdIncomesByLadCodeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GrossDisposableHouseholdIncomesByLadCodeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "798b567a243f008c83b13e9e6462abe8",
    "id": null,
    "metadata": {},
    "name": "GrossDisposableHouseholdIncomesByLadCodeQuery",
    "operationKind": "query",
    "text": "query GrossDisposableHouseholdIncomesByLadCodeQuery(\n  $ladCode: String!\n) {\n  grossDisposableHouseholdIncomesByLadCode(ladCode: $ladCode) {\n    labels\n    datasets {\n      code\n      values\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d80832ea2605cdb67ec73d6cb8455839";

export default node;
