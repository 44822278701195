// Operators.tsx

import React, { startTransition, useEffect, useMemo, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { OperatorsQuery as OperatorsQueryType } from '../../graphql/queries/__generated__/OperatorsQuery.graphql'
import { OperatorsQuery } from '../../graphql/queries/Operators'
import { Page } from '../../layout/page/Page'
import { SpaceBetween } from '../../shared/layout/Alignment'
import { Container, Row } from '../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { SegmentedButton } from '../../shared/SegmentedButton'
import { Paragraph } from '../../shared/Text'
import { PageTitle } from '../../shared/Text'
import { OperatorsOperatorFragment$key } from './__generated__/OperatorsOperatorFragment.graphql'
import { AllOperatorsTable } from './AllOperatorsTable'
import { OperatorsOperatorFragment } from './OperatorsOperatorFragment'

const Operators: React.FC = () => {
	const limit = 100
	const [offset, setOffset] = useState(0)
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const pageParam = searchParams.get('page')

	useEffect(() => {
		const page = pageParam ? parseInt(pageParam, 10) : 1
		setOffset((page - 1) * limit)
	}, [pageParam])

	const data = useLazyLoadQuery<OperatorsQueryType>(OperatorsQuery, { limit, offset })

	const tableData = useMemo(() => {
		return data.operators?.operators || []
	}, [data])

	const operators = tableData.map((op: OperatorsOperatorFragment$key) => {
		return useFragment(OperatorsOperatorFragment, op)
	})

	const handleNextPage = () => {
		if (tableData.length === limit) {
			startTransition(() => {
				setOffset((prevOffset) => prevOffset + limit)
				navigate(`?page=${Math.floor((offset + limit) / limit) + 1}`)
			})
		}
	}

	const handlePrevPage = () => {
		if (offset > 0) {
			startTransition(() => {
				setOffset((prevOffset) => Math.max(prevOffset - limit, 0))
				navigate(`?page=${Math.floor((offset - limit) / limit) + 1}`)
			})
		}
	}

	const buttonOpts = [
		{
			children: <ChevronLeft />,
			value: 'prev',
			disabled: offset === 0,
		},
		{
			children: <ChevronRight />,
			value: 'next',
			disabled: tableData.length < limit,
		},
	]

	return (
		<Page title="Operators">
			<Container>
				<Row>
					<div className="col-12">
						<PageTitle>Operators</PageTitle>
					</div>
				</Row>
				<VerticalSpace size="lg" />
				<Row>
					<div className="col-12">
						<Panel>
							{tableData.length > 0 ? (
								<>
									<SpaceBetween className="pagination">
										<Paragraph>
											Showing {offset + 1} to {offset + tableData.length}
										</Paragraph>
										<SegmentedButton
											options={buttonOpts}
											onClick={(value) => {
												if (value === 'prev') handlePrevPage()
												else if (value === 'next') handleNextPage()
											}}
										/>
									</SpaceBetween>
									<HorizontalSpace size="sm" />
									<AllOperatorsTable operators={operators} />
									<SpaceBetween className="pagination">
										<Paragraph>
											Showing {offset + 1} to {offset + tableData.length}
										</Paragraph>
										<SegmentedButton
											options={buttonOpts}
											onClick={(value) => {
												if (value === 'prev') handlePrevPage()
												else if (value === 'next') handleNextPage()
											}}
										/>
									</SpaceBetween>
								</>
							) : (
								<Paragraph>No operators found.</Paragraph>
							)}
						</Panel>
					</div>
				</Row>
			</Container>
		</Page>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Operators />
		</React.Suspense>
	)
}

export { Suspended as Operators }
