import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { RadiusValues } from '../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { UnemploymentRatesByCaQuery as UerRequestQuery } from '../graphql/queries/__generated__/UnemploymentRatesByCaQuery.graphql'
import { UnemploymentRatesByCaQuery as UerResponseType } from '../graphql/queries/UnemploymentRatesByCa'
import { Col, Container, Row } from '../shared/layout/Grid'
import { VerticalSpace } from '../shared/layout/Space'
import { Loading } from '../shared/Loading'
import { Panel } from '../shared/Panel'
import { ColoredBold, Heading } from '../shared/Text'
import { theme } from '../Theme'
import { formatCurrency, formatPercentage } from '../utils/formatNumber'
import { CareFeeByLad } from './careFeesByLad/CareFeesByLad'
import { PopulationByCa } from './populationByCa/PopulationByCa'
import { UnemploymentRatesByCa } from './unemploymentRatesByCa/UnemploymentRatesByCa'

interface CaQuickFactsProps {
	oaCode: string
	radius: RadiusValues
	ladCode: string
}

const CaQuickFacts: React.FC<CaQuickFactsProps> = ({ oaCode, radius, ladCode }) => {
	const { unemploymentRatesByCa: uer } = useLazyLoadQuery<UerRequestQuery>(UerResponseType, {
		oaCode,
		radius,
	})

	const uerCa = uer.datasets[0].values[uer.datasets[0].values.length - 1]
	const uerCountry = uer.datasets[uer.datasets.length - 1].values[uer.datasets[0].values.length - 1]

	const title = 'Catchment Area'
	const subTitle = `region, country`

	const props = {
		nationalAvgHousePrice: formatCurrency(308605),
		nationalElderlyPopulationShare: formatPercentage(19.1),
		nationalGdhi: formatCurrency(22213),
		nationalUnemployment: formatPercentage(uerCountry),
		unemployment: formatPercentage(uerCa),
	}

	console.log(props)

	return (
		<>
			<Container removePadding>
				<Row>
					<Col count={12}>
						<Panel padding={32}>
							<Heading level={1}>{title}</Heading>
							<ColoredBold color={theme.colors.grey}>{`${subTitle}`} </ColoredBold>
						</Panel>
					</Col>
				</Row>
				<VerticalSpace size="sm" />
				<Row>
					<Col count={6}>
						<CareFeeByLad ladCode={ladCode} />
					</Col>
					<Col count={6}>
						<PopulationByCa oaCode={oaCode} radius={radius} />
					</Col>
				</Row>
				<VerticalSpace size="sm" />
				<Row>
					<Col count={6}>
						<UnemploymentRatesByCa oaCode={oaCode} radius={radius} />
					</Col>
					<Col count={6}></Col>
				</Row>
			</Container>
			<VerticalSpace size="sm" />
		</>
	)
}

const Suspended: React.FC<CaQuickFactsProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<CaQuickFacts {...props} />
		</React.Suspense>
	)
}

export { Suspended as CaQuickFacts }
