import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import {
	RadiusValues,
	SocialGradesByCaQuery as QueryResponseType,
} from '../../graphql/queries/__generated__/SocialGradesByCaQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesRequest } from '../../graphql/queries/GeographiesByCodes'
import { SocialGradesByCaQuery } from '../../graphql/queries/SocialGradesByCa'
import { Model } from '../../shared/charts/types'
import { SocialGradeChart } from './SocialGradeChart'

interface CaSocialGradeChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaSocialGradeChart: React.FC<CaSocialGradeChartProps> = ({ oaCode, radius }) => {
	const { socialGradesByCa: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<QueryResponseType>(
		SocialGradesByCaQuery,
		{ oaCode, radius },
	)

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodesRequest, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || 'Catchment Area',
			values: [...d.values],
		})),
	}

	return <SocialGradeChart model={model} />
}

export { CaSocialGradeChart }
