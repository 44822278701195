import React from 'react'

import { BarChart } from '../../shared/charts/BarChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface ElderlyWithSevereDementiaChartProps {
	model: Model
}

const ElderlyWithSevereDementiaChart: React.FC<ElderlyWithSevereDementiaChartProps> = ({ model }) => {
	return (
		<>
			<Subtitle>Elderly with Severe Dementia</Subtitle>
			<Paragraph>The number of elderly people with severe dementia over time.</Paragraph>
			<VerticalSpace size="sm" />
			<BarChart horizontal={false} stacked={false} model={model} showLabels={true} alpha={0.9} aspectRatio={1} />
		</>
	)
}

export { ElderlyWithSevereDementiaChart }
