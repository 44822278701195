import React from 'react'

import { BarChart } from '../../shared/charts/BarChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface ElderlyCareNeedsChartProps {
	model: Model
}

const ElderlyCareNeedsChart: React.FC<ElderlyCareNeedsChartProps> = ({ model }) => {
	return (
		<>
			<Subtitle>Elderly Population with Care Needs</Subtitle>
			<Paragraph>
				Count of Elderly eligible for Attendance Allowance | Based on the newest data from the Department for Work and
				Pensions
			</Paragraph>
			<VerticalSpace size="sm" />
			<BarChart horizontal={false} stacked={true} model={model} showLabels={true} alpha={0.9} aspectRatio={1} />
		</>
	)
}

export { ElderlyCareNeedsChart }
