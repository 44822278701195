import graphql from 'babel-plugin-relay/macro'

export const OperatorsTableFragment = graphql`
	fragment OperatorsTableFragment on Operator {
		id
		brandName
		name
		registrationDate
		serviceTypes
		website
		sites {
			id
			careHome
			currentRatings
			registrationDate
			localAuthority
			ladCode
			numberOfBeds
			servicesProviders
			specialisms
			region
		}
	}
`
