import { linearRegression } from './linearRegression'

function forecastData(historicalData: { [year: number]: number }, forecastYears: number[]): { [year: number]: number } {
	const years = Object.keys(historicalData).map((yearStr) => parseInt(yearStr, 10))
	const values = years.map((year) => historicalData[year])

	const forecastData: { [year: number]: number } = {}

	if (years.length === 0) {
		forecastYears.forEach((year) => {
			forecastData[year] = 0
		})
		return forecastData
	}

	if (years.length === 1) {
		// Return the same value for all forecast years
		forecastYears.forEach((year) => {
			forecastData[year] = values[0]
		})
		return forecastData
	}

	// Perform linear regression
	const regressionResult = linearRegression(years, values)

	// Predict values for forecast years
	forecastYears.forEach((year) => {
		forecastData[year] = Math.round(regressionResult.slope * year + regressionResult.intercept)
	})

	return forecastData
}

export { forecastData }
