import React from 'react'

import { CaElderlyCareNeedPrevalenceRateChart } from '../../../charts/ElderlyCareNeedPrevalenceRate/CaElderlyCareNeedPrevalenceRateChart'
import { CaElderlyCareNeedsChart } from '../../../charts/ElderlyCareNeeds/CaElderlyCareNeedsChart'
import { CaElderlyPopulationIn10YearsChart } from '../../../charts/ElderlyPopulationIn10Years/CaElderlyPopulationIn10YearsChart'
import { CaElderlyPopulationShareChart } from '../../../charts/ElderlyPopulationShare/CaElderlyPopulationShareChart'
import { RadiusValues } from '../../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { LeftAligned } from '../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { PageTitle, Paragraph } from '../../../shared/Text'

interface ElderlyProps {
	oaCode: string
	radius: RadiusValues
}

const Elderly: React.FC<ElderlyProps> = ({ oaCode, radius }) => {
	return (
		<Container>
			<VerticalSpace size="xl" />
			<Row>
				<LeftAligned>
					<HorizontalSpace size="md" />
					<Col count={7}>
						<PageTitle>Elderly Demographics</PageTitle>
						<Paragraph>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
							et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
							sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
						</Paragraph>
					</Col>
				</LeftAligned>
			</Row>
			<VerticalSpace size="xl" />
			<Row>
				<Col count={6}>
					<Panel>
						<CaElderlyPopulationIn10YearsChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<CaElderlyPopulationShareChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={6}>
					<Panel>
						<CaElderlyCareNeedsChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<CaElderlyCareNeedPrevalenceRateChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<ElderlyProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Elderly {...props} />
		</React.Suspense>
	)
}

export { Suspended as Elderly }
