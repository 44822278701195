import React, { useEffect, useState } from 'react'

import { RadiusValues } from '../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { SecondaryButton } from './Button'

interface LatLongRadiusProps {
	lat: number
	long: number
	radius: RadiusValues
	postCode?: string
	onGoClick: (lat: number, long: number, radius: RadiusValues) => void
}

const LatLongRadius: React.FC<LatLongRadiusProps> = ({ lat, long, radius, postCode, onGoClick }) => {
	const [localState, setLocalState] = useState({
		// lat,
		// long,
		radius,
		postCode,
	})

	useEffect(() => {
		setLocalState({
			// lat,
			// long,
			radius,
			postCode,
		})
	}, [lat, long, radius, postCode])

	useEffect(() => {
		const fetchPostCodeData = async () => {
			const response = await fetch(`https://api.postcodes.io/postcodes?lon=${long}&lat=${lat}`)
			const { result } = await response.json()
			if (result) {
				setLocalState({
					...localState,
					postCode: result[0].postcode,
				})
			}
		}

		fetchPostCodeData()
	}, [lat, long])

	// const handleLatChange = (lat: string) => {
	// 	setLocalState({ ...localState, lat: parseFloat(lat) })
	// }

	// const handleLongChange = (long: string) => {
	// 	setLocalState({ ...localState, long: parseFloat(long) })
	// }

	const handleRadiusChange = (radius: RadiusValues) => {
		setLocalState({ ...localState, radius })
	}

	const handleGoClick = async () => {
		const response = await fetch(`https://api.postcodes.io/postcodes/${localState.postCode}`)
		const { result } = await response.json()
		if (result) {
			// setLocalState({
			// 	...localState,
			// 	lat: result.latitude,
			// 	long: result.longitude,
			// })
			onGoClick(result.latitude, result.longitude, localState.radius)
		} else {
			alert('Invalid postcode')
		}
	}

	const handleOnPostcodeChange = (postCode: string) => {
		setLocalState({ ...localState, postCode })
	}

	return (
		<>
			<div className="input-group mb-1 d-flex" style={{ width: 'auto' }}>
				<span className="input-group-text" id="lat">
					Postcode
				</span>
				<input
					type="text"
					className="form-control"
					id="postCode"
					aria-describedby="postCode"
					value={localState.postCode}
					onChange={(e) => handleOnPostcodeChange(e.target.value)}
					style={{ maxWidth: 100, textAlign: 'center' }}
				/>
				{/* <span className="input-group-text" id="lat">
					Lat
				</span>
				<input
					type="text"
					className="form-control"
					id="lat"
					aria-describedby="lat"
					value={localState.lat}
					onChange={(e) => handleLatChange(e.target.value)}
					style={{ maxWidth: 100 }}
					disabled={true}
				/>
				<span className="input-group-text" id="lng">
					Long
				</span>
				<input
					type="text"
					className="form-control"
					id="lng"
					aria-describedby="lng"
					value={localState.long}
					onChange={(e) => handleLongChange(e.target.value)}
					style={{ maxWidth: 100 }}
					disabled={true}
				/> */}
				<span className="input-group-text" id="lng">
					Lat/Long
				</span>
				<input
					type="text"
					className="form-control"
					id="lat-lng"
					aria-describedby="lat-lng"
					value={lat.toFixed(3) + ' , ' + long.toFixed(3)}
					style={{ maxWidth: 130, textAlign: 'center' }}
					disabled={true}
				/>
				<span className="input-group-text" id="radius">
					Radius
				</span>
				<select
					className="form-control"
					id="radius"
					aria-describedby="radius"
					value={localState.radius}
					onChange={(e) => handleRadiusChange(e.target.value as RadiusValues)}
					style={{ maxWidth: 50, textAlign: 'center' }}
				>
					<option value="ONE">1</option>
					<option value="THREE">3</option>
					<option value="FIVE">5</option>
					<option value="TEN">10</option>
				</select>
				<SecondaryButton onClick={handleGoClick}>Go</SecondaryButton>
			</div>
		</>
	)
}

export { LatLongRadius }
