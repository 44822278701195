import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { SitesByLadCodeQuery } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { SitesByLadCodeQuery as QueryRequestType } from '../../graphql/queries/SitesByLadCode'
import {
	ElderlyBedsTimeSeriesChartSiteFragment$data,
	ElderlyBedsTimeSeriesChartSiteFragment$key,
} from './__generated__/ElderlyBedsTimeSeriesChartSiteFragment.graphql'
import { ElderlyBedsTimeSeriesChart } from './ElderlyBedsTimeSeriesChart'
import { ElderlyBedsTimeSeriesChartSiteFragment } from './ElderlyBedsTimeSeriesChartSiteFragment'
import { getElderlyBeds } from './helpers'

interface ElderlyBedsTimeSeriesChartProps {
	ladCode: string
}

const LadElderlyBedsTimeSeriesChart: React.FC<ElderlyBedsTimeSeriesChartProps> = ({ ladCode }) => {
	const sitesData = useLazyLoadQuery<SitesByLadCodeQuery>(QueryRequestType, {
		ladCode: ladCode,
	})

	const sites = sitesData.sitesByLadCode.map((site: ElderlyBedsTimeSeriesChartSiteFragment$key) => {
		return useFragment<ElderlyBedsTimeSeriesChartSiteFragment$key>(ElderlyBedsTimeSeriesChartSiteFragment, site)
	}) as ElderlyBedsTimeSeriesChartSiteFragment$data[]

	const model = getElderlyBeds(sites)

	return <ElderlyBedsTimeSeriesChart model={model} />
}

export { LadElderlyBedsTimeSeriesChart }
