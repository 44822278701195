// src/utils/calculateElderlyWithCareNeedShare.ts

import { GeographiesByCodesQuery$data } from '../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { PopulationByLadCodeQuery$data as PopulationQueryDataType$Data } from '../graphql/queries/__generated__/PopulationByLadCodeQuery.graphql'
import { Model } from '../shared/charts/types'

export type Count = {
	code: string
	values: ReadonlyArray<number>
}

function calculateElderlyWithCareNeedShare(
	pop: PopulationQueryDataType$Data['populationByLadCode'],
	aaCounts: Count[],
	geos: GeographiesByCodesQuery$data['geographiesByCodes'],
): Model {
	return {
		labels: pop.years.map((year) => year.toString()),
		datasets: pop.geographies.map((geography) => {
			const geoPops = geography.ageRanges[0].values
			const awardCounts = aaCounts.find((r) => r.code === geography.code)

			const values = geoPops.map((pop, index) => {
				const count = awardCounts?.values[index] || 0
				return (count / pop) * 100
			})

			return {
				id: geos.find((g) => g.code === geography.code)?.name || 'Catchment Area',
				values,
			}
		}),
	}
}

export { calculateElderlyWithCareNeedShare }
