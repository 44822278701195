import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	AADisablingConditionsByCaQuery as DisResponse,
	RadiusValues,
} from '../../graphql/queries/__generated__/AADisablingConditionsByCaQuery.graphql'
import { AADisablingConditionsByCaQuery as DisRequest } from '../../graphql/queries/AADisablingConditionsByCa'
import { Model } from '../../shared/charts/types'
import { ElderlyWithSevereDementiaChart } from './ElderlyWithSevereDementiaChart'

interface CaElderlyWithSevereDementiaChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaElderlyWithSevereDementiaChart: React.FC<CaElderlyWithSevereDementiaChartProps> = ({ oaCode, radius }) => {
	const { aaDisablingConditionsByCa: dis } = useLazyLoadQuery<DisResponse>(DisRequest, {
		oaCode,
		radius,
	})

	const datasets = dis.datasets.map((dataset) => ({
		id: 'Dementia',
		values: dataset.values.map((value) => value),
	}))

	const caDataset = [datasets[0]]

	const model: Model = {
		labels: dis.labels.map((year) => year.toString()),
		datasets: caDataset,
	}

	return <ElderlyWithSevereDementiaChart model={model} />
}

export { CaElderlyWithSevereDementiaChart }
