import React from 'react'

import { Col, Container, Row } from '../shared/layout/Grid'
import { VerticalSpace } from '../shared/layout/Space'
import { Panel } from '../shared/Panel'
import { Heading } from '../shared/Text'
import { theme } from '../Theme'

export interface QuickFactsProps {
	avgHousePrice: string
	elderlyPopulationShare: string
	gdhi: string
	nationalAvgHousePrice: string
	nationalElderlyPopulationShare: string
	nationalGdhi: string
}

const QuickFacts: React.FC<QuickFactsProps> = ({
	avgHousePrice,
	elderlyPopulationShare,
	gdhi,
	nationalAvgHousePrice,
	nationalElderlyPopulationShare,
	nationalGdhi,
}) => {
	return (
		<Container removePadding>
			<Row>
				<Col count={6}>
					<QuickFact heading="Disposable Household Income" value={gdhi} comparison={nationalGdhi} />
				</Col>
				<Col count={6}>
					<QuickFact
						heading="Elderly Population Share"
						value={elderlyPopulationShare}
						comparison={nationalElderlyPopulationShare}
					/>
				</Col>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<Col count={6}></Col>
				<Col count={6}>
					<QuickFact heading="House Price Average" value={avgHousePrice} comparison={nationalAvgHousePrice} />
				</Col>
			</Row>
		</Container>
	)
}

interface QuickFactProps {
	heading: string
	value: string
	comparison: string
	isComparable?: boolean
}

const QuickFact: React.FC<QuickFactProps> = ({ heading, value, comparison, isComparable = true }) => {
	const color = isComparable ? (value > comparison ? theme.colors.primary : theme.colors.red) : theme.colors.darkText

	return (
		<Panel padding={32}>
			<Heading level={6}>{heading}</Heading>
			<Heading level={3} color={color}>
				{value}
			</Heading>
			<Heading level={6} color={theme.colors.grey}>
				{comparison}
			</Heading>
		</Panel>
	)
}

export { QuickFact, QuickFacts }
