import React from 'react'

import { LineChart } from '../../shared/charts/LineChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface DementiaPrevalenceRateChartProps {
	model: Model
}

const DementiaPrevalenceRateChart: React.FC<DementiaPrevalenceRateChartProps> = ({ model }) => {
	return (
		<>
			<>
				<Subtitle>Dementia Prevalence Rate</Subtitle>
				<Paragraph>Percentage of elderly population diagnosed with severe dementia over time.</Paragraph>
				<VerticalSpace size="sm" />
			</>
			<LineChart model={model} prefix="" suffix="%" dataLabelDisplayOption="showAll" aspectRatio={0.75} />
		</>
	)
}

export { DementiaPrevalenceRateChart }
