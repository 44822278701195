import React from 'react'

import { LadPopulationGrowthByAgeGroupChart } from '../../../../charts/PopulationGrowthByAgeGroup/LadPopulationGrowthByAgeGroupChart'
import { LadSocialGradeChart } from '../../../../charts/SocialGrade/LadSocialGradeChart'
import { LeftAligned } from '../../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../../shared/layout/Space'
import { Loading } from '../../../../shared/Loading'
import { Panel } from '../../../../shared/Panel'
import { SubtitleBox } from '../../../../shared/Text'
import { LadAIPopulationSummary } from './LadAIPopulationSummary'

interface PopulationProps {
	ladCode: string
}

const Population: React.FC<PopulationProps> = ({ ladCode }) => {
	return (
		<Container>
			<VerticalSpace size="xl" />
			<Row>
				<LeftAligned>
					<HorizontalSpace size="md" />
					<Col count={7}>
						<SubtitleBox>Population Report</SubtitleBox>
						<LadAIPopulationSummary ladCode={ladCode} />
					</Col>
				</LeftAligned>
			</Row>
			<VerticalSpace size="xl" />
			<Row>
				<Col count={6}>
					<Panel>
						<LadPopulationGrowthByAgeGroupChart ladCode={ladCode} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<LadSocialGradeChart ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<PopulationProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Population ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as Population }
