import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { awardTypesLabelMap } from '../../Constants'
import { AAAwardTypesByLadCodeQuery as QueryResponseType } from '../../graphql/queries/__generated__/AAAwardTypesByLadCodeQuery.graphql'
import { AAAwardTypesByLadCodeQuery as QueryRequestType } from '../../graphql/queries/AAAwardTypesByLad'
import { Model } from '../../shared/charts/types'
import { ElderlyCareNeedsChart } from './ElderlyCareNeedsChart'

interface LadElderlyCareNeedsChartProps {
	ladCode: string
}

const LadElderlyCareNeedsChart: React.FC<LadElderlyCareNeedsChartProps> = ({ ladCode }) => {
	const { aaAwardTypesByLadCode: aa } = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		ladCode,
	})

	const model: Model = {
		labels: [...aa.labels],
		datasets: [
			{
				id: awardTypesLabelMap.higherRate,
				values: aa.higherRate.length > 0 ? [...aa.higherRate[0].values] : [],
			},
			{
				id: awardTypesLabelMap.lowerRate,
				values: aa.lowerRate.length > 0 ? [...aa.lowerRate[0].values] : [],
			},
		],
	}

	return <ElderlyCareNeedsChart model={model} />
}

export { LadElderlyCareNeedsChart }
