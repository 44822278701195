function formatNumber(number: number, minimumFractionDigits: number = 0, maximumFractionDigits: number = 2): string {
	return number.toLocaleString('en-GB', {
		minimumFractionDigits,
		maximumFractionDigits,
	})
}

function formatCurrency(number: number, maxDecimals: number = 2): string {
	return '£ ' + formatNumber(number, maxDecimals)
}

function formatPercentage(number: number, minimumFractionDigits: number = 0, maxDecimals: number = 2): string {
	return formatNumber(number, minimumFractionDigits, maxDecimals) + '%'
}

function formatter(prefix: string, suffix: string) {
	return (value: string | number) => {
		const formattedValue = formatNumber(Number(value))
		return `${prefix}${formattedValue}${suffix}`
	}
}
export { formatCurrency, formatNumber, formatPercentage, formatter }
