import React from 'react'

import { BarChart } from '../../shared/charts/BarChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { ParagraphSmallBold, TitleBox } from '../../shared/Text'

interface ElderlySitesChartProps {
	model: Model
}

const ElderlySitesChart: React.FC<ElderlySitesChartProps> = ({ model }) => {
	return (
		<>
			<TitleBox>Elderly Care Home Sites</TitleBox>
			<ParagraphSmallBold>Count of Care Home Sites for Ageband 65+ in 2014 and 2024</ParagraphSmallBold>
			<VerticalSpace size="sm" />
			<BarChart horizontal={false} stacked={false} model={model} showLabels={true} alpha={1} aspectRatio={2} />
		</>
	)
}
export { ElderlySitesChart }
