import React from 'react'

interface SwitchProps {
	on: boolean
	onClick: (checked: boolean) => void
	label?: string
}

const Switch: React.FC<SwitchProps> = ({ label, on, onClick }) => {
	return (
		<div className="form-check form-switch">
			<input
				className="form-check-input"
				type="checkbox"
				role="switch"
				id={`${label}-switch`}
				checked={on}
				onChange={(e) => onClick(e.target.checked)}
			/>
			{label && (
				<label className="form-check-label" style={{ whiteSpace: 'nowrap' }} htmlFor={`${label}-switch`}>
					{label}
				</label>
			)}
		</div>
	)
}

export { Switch }
