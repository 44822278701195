import React from 'react'

import { LadElderlyBedShortagesForecastChart } from '../../../charts/ElderlyBedShortagesForecastChart/LadElderlyBedShortagesForecastChart'
import { ElderlyCareForecast } from '../../../charts/ElderlyCareForecast/ElderlyCareForecast'
import { Col, Row } from '../../../shared/layout/Grid'
import { VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { ParagraphSmallBold, SubtitleBox, TitleBox } from '../../../shared/Text'

interface DemandSupplyProps {
	ladCode: string
}

const DemandSupply: React.FC<DemandSupplyProps> = ({ ladCode }) => {
	return (
		<>
			<Row>
				<div className="col-12">
					<Panel>
						<Row>
							<Col count={6}>
								<SubtitleBox>Demand and Supply Analysis</SubtitleBox>
								<TitleBox>Urgent Strategies Needed for Rising Demand in Elderly Care</TitleBox>
								<ParagraphSmallBold>
									Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
									labore et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur
									sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
									diam voluptua.
								</ParagraphSmallBold>
							</Col>
						</Row>
					</Panel>
				</div>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<div className="col-6">
					<Panel>
						<VerticalSpace size="sm" />
						<ElderlyCareForecast ladCode={ladCode} />
						<VerticalSpace size="sm" />
					</Panel>
				</div>
				<div className="col-6">
					<Panel>
						<VerticalSpace size="sm" />
						<LadElderlyBedShortagesForecastChart ladCode={ladCode} />
						<VerticalSpace size="sm" />
					</Panel>
				</div>
			</Row>
			<VerticalSpace size="sm" />
		</>
	)
}

const Suspended: React.FC<DemandSupplyProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<DemandSupply ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as DemandSupply }
