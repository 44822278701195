import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { GrossDisposableHouseholdIncomesByLadCodeQuery as GDHIResponse } from '../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByLadCodeQuery.graphql'
import { HousePricesByLadCodeQuery as HousePricesResponse } from '../../graphql/queries/__generated__/HousePricesByLadCodeQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesRequest } from '../../graphql/queries/GeographiesByCodes'
import { GrossDisposableHouseholdIncomesByLadCodeQuery as GDHIRequest } from '../../graphql/queries/GrossDisposableHouseholdIncomesByLadCode'
import { HousePricesByLadCodeQuery as HousePricesRequest } from '../../graphql/queries/HousePricesByLad'
import { calculateYearsToBuy } from '../../utils/calculateYearsToBuy'
import { HousingYearsToBuyChart } from './HousingYearsToBuyChart'

interface LadHousingYearsToBuyChartProps {
	ladCode: string
}

const LadHousingYearsToBuyChart: React.FC<LadHousingYearsToBuyChartProps> = ({ ladCode }) => {
	const { housePricesByLadCode: hp } = useLazyLoadQuery<HousePricesResponse>(HousePricesRequest, { ladCode })

	const { grossDisposableHouseholdIncomesByLadCode: gdhi } = useLazyLoadQuery<GDHIResponse>(GDHIRequest, {
		ladCode,
	})

	const codes = hp.datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodesRequest, {
		codes,
	})

	const model = calculateYearsToBuy(hp, gdhi, geos)

	return <HousingYearsToBuyChart model={model} />
}

export { LadHousingYearsToBuyChart }
