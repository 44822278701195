/**
 * @generated SignedSource<<d6dc473126fa5825172644d116cbee4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RadiusValues = "FIVE" | "ONE" | "TEN" | "THREE" | "%future added value";
export type SitesByCaQuery$variables = {
  oaCode: string;
  radius: RadiusValues;
};
export type SitesByCaQuery$data = {
  readonly sitesByCa: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"CareHomesSitesByCaQueryFragment" | "ElderlyBedShortagesForecastChartSiteFragment" | "ElderlyBedsTimeSeriesChartSiteFragment" | "ElderlySitesChartSiteFragment" | "SitesTableFragment">;
  }>;
};
export type SitesByCaQuery = {
  response: SitesByCaQuery$data;
  variables: SitesByCaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "oaCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "radius"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "oaCode",
    "variableName": "oaCode"
  },
  {
    "kind": "Variable",
    "name": "radius",
    "variableName": "radius"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SitesByCaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "sitesByCa",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CareHomesSitesByCaQueryFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SitesTableFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlySitesChartSiteFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyBedShortagesForecastChartSiteFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyBedsTimeSeriesChartSiteFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SitesByCaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "sitesByCa",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postalAddressTownCity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "operatorName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfBeds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registrationDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentRatings",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "specialisms",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serviceTypes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "website",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fc537dec1763b5dbf4244944497d4bb",
    "id": null,
    "metadata": {},
    "name": "SitesByCaQuery",
    "operationKind": "query",
    "text": "query SitesByCaQuery(\n  $oaCode: String!\n  $radius: RadiusValues!\n) {\n  sitesByCa(oaCode: $oaCode, radius: $radius) {\n    ...CareHomesSitesByCaQueryFragment\n    ...SitesTableFragment\n    ...ElderlySitesChartSiteFragment\n    ...ElderlyBedShortagesForecastChartSiteFragment\n    ...ElderlyBedsTimeSeriesChartSiteFragment\n  }\n}\n\nfragment CareHomesSitesByCaQueryFragment on Site {\n  id\n  name\n  location\n}\n\nfragment ElderlyBedShortagesForecastChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n}\n\nfragment ElderlyBedsTimeSeriesChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n}\n\nfragment ElderlySitesChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n}\n\nfragment SitesTableFragment on Site {\n  id\n  name\n  postalAddressTownCity\n  operatorName\n  numberOfBeds\n  registrationDate\n  currentRatings\n  specialisms\n  serviceTypes\n  website\n}\n"
  }
};
})();

(node as any).hash = "78a953465f6228eac459b29e94a50906";

export default node;
