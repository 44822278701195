import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { RadiusValues } from '../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { PopulationByCaQuery as PopByCaQueryResponse } from '../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesRequest } from '../../graphql/queries/GeographiesByCodes'
import { PopulationByCaQuery as PopByCaQuery } from '../../graphql/queries/PopulationByCa'
import { Dataset } from '../../shared/charts/types'
import { ElderlyPopulationShareChart } from './ElderlyPopulationShareChart'

interface CaElderlyPopulationShareChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaElderlyPopulationShareChart: React.FC<CaElderlyPopulationShareChartProps> = ({ oaCode, radius }) => {
	const { populationByCa: elderly } = useLazyLoadQuery<PopByCaQueryResponse>(PopByCaQuery, {
		oaCode,
		radius,
		startYear: currentYear,
		futureOffset: 10,
		minAge: 65,
		rangeSize: 100,
		includeIntermediates: false,
	})

	// Fetch total population data
	const { populationByCa: total } = useLazyLoadQuery<PopByCaQueryResponse>(PopByCaQuery, {
		oaCode,
		radius,
		startYear: currentYear,
		futureOffset: 10,
		minAge: 0,
		rangeSize: 100,
		includeIntermediates: false,
	})

	const codes = total.geographies.map((d) => d.code)
	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodesRequest, {
		codes,
	})

	const labels = total.years.map((year) => year.toString())
	const datasets: Dataset[] = total.geographies.map((geography, i) => ({
		id: geos.find((g) => g.code === geography.code)?.name || 'Catchment Area',
		values: geography.ageRanges[0].values.map((value, index) => {
			return (elderly.geographies[i].ageRanges[0].values[index] / value) * 100
		}),
	}))

	return <ElderlyPopulationShareChart model={{ labels, datasets }} />
}

export { CaElderlyPopulationShareChart }
