import graphql from 'babel-plugin-relay/macro'

export const SocialGradesByCaQuery = graphql`
	query SocialGradesByCaQuery($oaCode: String!, $radius: RadiusValues!) {
		socialGradesByCa(oaCode: $oaCode, radius: $radius) {
			labels
			datasets {
				code
				values
			}
		}
	}
`
