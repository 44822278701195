import {
	CellContext,
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'
import React from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'

export interface RowData {
	id: string
}

interface DataTableProps {
	columns: ColumnDef<RowData, any>[]
	data: ReadonlyArray<RowData>
	onRowClick?: (id: string) => void
	onCell: (cell: CellContext<RowData, unknown>, i: number) => React.ReactNode
}

const DataTable: React.FC<DataTableProps> = ({ columns, data, onRowClick, onCell }) => {
	const [sorting, setSorting] = React.useState<SortingState>([])

	const memoizedColumns = React.useMemo(() => columns, [columns])
	const memoizedData = React.useMemo(() => [...data], [data])

	const table = useReactTable({
		data: memoizedData,
		columns: memoizedColumns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})

	return (
		<div className="table-responsive">
			<table className="table table-hover table-sm">
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								const sorted = header.column.getIsSorted()
								return (
									<th
										key={header.id}
										colSpan={header.colSpan}
										onClick={header.column.getToggleSortingHandler()}
										style={{ cursor: 'pointer', userSelect: 'none' }}
									>
										{flexRender(header.column.columnDef.header, header.getContext())}
										<span style={{ marginLeft: '5px' }}>
											{sorted === 'asc' && <ChevronUp />}
											{sorted === 'desc' && <ChevronDown />}
										</span>
									</th>
								)
							})}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map((row) => (
						<tr key={row.id} onClick={() => onRowClick && onRowClick(row.original.id)} style={{ cursor: 'pointer' }}>
							{row.getVisibleCells().map((cell, i) => (
								<td key={cell.id}>{onCell(cell.getContext(), i)}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export { DataTable }
