import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { AAAwardTypesByLadCodeQuery as QueryResponseType } from '../../graphql/queries/__generated__/AAAwardTypesByLadCodeQuery.graphql'
import { SitesByLadCodeQuery as SitesQueryType } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { AAAwardTypesByLadCodeQuery as QueryRequestType } from '../../graphql/queries/AAAwardTypesByLad'
import { SitesByLadCodeQuery as SitesQueryRequest } from '../../graphql/queries/SitesByLadCode'
import { ElderlyBedShortagesForecastChartSiteFragment$key } from './__generated__/ElderlyBedShortagesForecastChartSiteFragment.graphql'
import { ElderlyBedShortagesForecastChart } from './ElderlyBedShortagesForecastChart'
import { ElderlyBedShortagesForecastChartSiteFragment } from './ElderlyBedShortagesForecastChartSiteFragment'
import { getBedSupplyAndDemandHistoricalForecast } from './helpers'

const startYear = 2020
const endYear = 2032

interface LadChartProps {
	ladCode: string
}

const LadElderlyBedShortagesForecastChart: React.FC<LadChartProps> = ({ ladCode }) => {
	// Fetch data
	const { aaAwardTypesByLadCode: aaData } = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		ladCode,
	})

	const sitesData = useLazyLoadQuery<SitesQueryType>(SitesQueryRequest, {
		ladCode: ladCode,
	})

	const sites = sitesData.sitesByLadCode.map((site: ElderlyBedShortagesForecastChartSiteFragment$key) => {
		return useFragment(ElderlyBedShortagesForecastChartSiteFragment, site)
	})

	// Process data
	const { labels, datasets } = getBedSupplyAndDemandHistoricalForecast(aaData, sites, startYear, endYear)

	return <ElderlyBedShortagesForecastChart model={{ labels, datasets }} />
}

export { LadElderlyBedShortagesForecastChart }
