/**
 * @generated SignedSource<<0f183458c2b63554ca9d79846682edf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CareFeesByLadCodeQuery$variables = {
  ladCode: string;
};
export type CareFeesByLadCodeQuery$data = {
  readonly careFeeRatesByLadCode: {
    readonly " $fragmentSpreads": FragmentRefs<"CareFeesQuickFactFragment">;
  };
};
export type CareFeesByLadCodeQuery = {
  response: CareFeesByLadCodeQuery$data;
  variables: CareFeesByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCode"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ladCode",
    "variableName": "ladCode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CareFeesByLadCodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CareFeeRates",
        "kind": "LinkedField",
        "name": "careFeeRatesByLadCode",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CareFeesQuickFactFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CareFeesByLadCodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CareFeeRates",
        "kind": "LinkedField",
        "name": "careFeeRatesByLadCode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "labels",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CareFeeRateData",
            "kind": "LinkedField",
            "name": "datasets",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "values",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bcea8a80352472defe4c8b1b72ac7dd1",
    "id": null,
    "metadata": {},
    "name": "CareFeesByLadCodeQuery",
    "operationKind": "query",
    "text": "query CareFeesByLadCodeQuery(\n  $ladCode: String!\n) {\n  careFeeRatesByLadCode(ladCode: $ladCode) {\n    ...CareFeesQuickFactFragment\n  }\n}\n\nfragment CareFeesQuickFactFragment on CareFeeRates {\n  labels\n  datasets {\n    code\n    values\n  }\n}\n"
  }
};
})();

(node as any).hash = "48d81668198178869bf8182e94926a6b";

export default node;
