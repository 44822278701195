import React from 'react'

import { LinkButton, PrimaryButton } from './Button'

export interface Link {
	label: string
	key: string
	active?: boolean
	disabled?: boolean
}

export interface LinkbarProps {
	links: Link[]
	value: string
	onClick: (key: string) => void
}
const LinkBar: React.FC<LinkbarProps> = ({ links, value, onClick }) => {
	links.forEach((link) => {
		link.active = link.key === value
	})

	return (
		<>
			<nav>
				<div className="nav nav-pills" id="nav-tab" role="tablist">
					{links.map((link) => {
						if (link.active) {
							return (
								<div key={link.key} style={{ marginRight: 5 }}>
									<PrimaryButton disabled={link.disabled} onClick={() => onClick(link.key)}>
										{link.label}
									</PrimaryButton>
								</div>
							)
						} else {
							return (
								<div key={link.key} style={{ marginRight: 5 }}>
									<LinkButton key={link.key} disabled={link.disabled} onClick={() => onClick(link.key)}>
										{link.label}
									</LinkButton>
								</div>
							)
						}
					})}
				</div>
			</nav>
		</>
	)
}

export { LinkBar }
