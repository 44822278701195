/**
 * @generated SignedSource<<45747a8f9402522ff5915d7c73381fb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RadiusValues = "FIVE" | "ONE" | "TEN" | "THREE" | "%future added value";
export type AAUniversalCreditsByCaQuery$variables = {
  oaCode: string;
  radius: RadiusValues;
};
export type AAUniversalCreditsByCaQuery$data = {
  readonly aaUniversalCreditsByCa: {
    readonly datasets: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly labels: ReadonlyArray<string>;
  };
};
export type AAUniversalCreditsByCaQuery = {
  response: AAUniversalCreditsByCaQuery$data;
  variables: AAUniversalCreditsByCaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "oaCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "radius"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "oaCode",
        "variableName": "oaCode"
      },
      {
        "kind": "Variable",
        "name": "radius",
        "variableName": "radius"
      }
    ],
    "concreteType": "AAUniversalCredits",
    "kind": "LinkedField",
    "name": "aaUniversalCreditsByCa",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labels",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AAUniversalCreditData",
        "kind": "LinkedField",
        "name": "datasets",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "values",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AAUniversalCreditsByCaQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AAUniversalCreditsByCaQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "67705c5f3aeebe103aec3fe12074230e",
    "id": null,
    "metadata": {},
    "name": "AAUniversalCreditsByCaQuery",
    "operationKind": "query",
    "text": "query AAUniversalCreditsByCaQuery(\n  $oaCode: String!\n  $radius: RadiusValues!\n) {\n  aaUniversalCreditsByCa(oaCode: $oaCode, radius: $radius) {\n    labels\n    datasets {\n      code\n      values\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b2c185334d706853aefbbe621f33176d";

export default node;
