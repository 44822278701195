import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import {
	RadiusValues,
	SitesByCaQuery as SitesResponse,
} from '../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { SitesByCaQuery as SitesRequest } from '../../graphql/queries/SitesByCa'
import { SitesTableFragment$key } from './__generated__/SitesTableFragment.graphql'
import { SitesTable } from './SitesTable'
import { SitesTableFragment } from './SitesTableFragment'

interface SitesTableProps {
	oaCode: string
	radius: RadiusValues
}

const CaSitesTable: React.FC<SitesTableProps> = ({ oaCode, radius }) => {
	const data = useLazyLoadQuery<SitesResponse>(SitesRequest, { oaCode, radius })

	const sitesData = React.useMemo(() => {
		return data.sitesByCa || []
	}, [data])

	// Use useFragment on each item in the array
	const sites = sitesData.map((site: SitesTableFragment$key) => {
		return useFragment(SitesTableFragment, site)
	})

	return <SitesTable sites={sites} />
}

export { CaSitesTable }
