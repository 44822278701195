import * as turf from '@turf/turf'
import { Feature, MultiPolygon, Polygon } from 'geojson'
import React, { useState } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { CaElderlyBedsTimeSeriesChart } from '../../../../charts/ElderlyBedsTimeSeriesChart/CaElderlyBedsTimeSeriesChart copy'
import { CaElderlySitesChart } from '../../../../charts/ElderlySitesChart/CaElderlySitesChart'
import { OutputAreasByMileRadiusQuery as AreaQueryResponseType } from '../../../../graphql/queries/__generated__/OutputAreasByMileRadiusQuery.graphql'
import { RadiusValues } from '../../../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { SitesByCaQuery as SitesQueryResponseType } from '../../../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { OutputAreasByMileRadiusQuery as AreaQueryRequestType } from '../../../../graphql/queries/OutputAreasByMileRadius'
import { SitesByCaQuery as SitesQueryRequestType } from '../../../../graphql/queries/SitesByCa'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../shared/layout/Space'
import { Loading } from '../../../../shared/Loading'
import { MapFeatures, Marker } from '../../../../shared/map/MapFeatures'
import { CaOperatorsTable } from '../../../../shared/operatorsTable/CaOperatorsTable'
import { Panel } from '../../../../shared/Panel'
import { CaSitesTable } from '../../../../shared/sitesTable/CaSitesTable'
import { Heading, Paragraph, SubtitleBox } from '../../../../shared/Text'
import { ToggleButtonGroup } from '../../../../shared/ToggleButtonGroup'
import { theme } from '../../../../Theme'
import { combineCollection } from '../../../../utils/featureCollections'
import { CaptureAreaFragment$key } from '../../__generated__/CaptureAreaFragment.graphql'
import { CaptureAreaFragment } from '../../CaptureAreaFragment'
import { CareHomesSitesByCaQueryFragment$key } from './__generated__/CareHomesSitesByCaQueryFragment.graphql'
import { CareHomesSitesByCaQueryFragment } from './CareHomesSitesByCa'

interface CareHomesProps {
	oaCode: string
	radius: RadiusValues
	lat: number
	long: number
}

const CareHomes: React.FC<CareHomesProps> = ({ oaCode, radius, lat, long }) => {
	const [view, setView] = useState('careHomes')

	const options = [
		{ label: 'Care Homes', value: 'careHomes' },
		{ label: 'Operators', value: 'operators' },
	]

	const handleViewChange = (value: string) => {
		setView(value)
	}

	const { outputAreasByMileRadius } = useLazyLoadQuery<AreaQueryResponseType>(AreaQueryRequestType, {
		lat: `${lat}`,
		long: `${long}`,
		radius,
	})
	const ca = useFragment<CaptureAreaFragment$key>(CaptureAreaFragment, outputAreasByMileRadius)

	const features: Feature<Polygon | MultiPolygon>[] = ca.outputAreas.map((o) => {
		const geometry = JSON.parse(o.geometry!)
		return {
			type: 'Feature',
			geometry,
			properties: {
				color: theme.colors.primary,
				alpha: 0,
			},
		}
	})

	const featureCollection = turf.featureCollection(features)
	const featureCollections = combineCollection(featureCollection)

	const { sitesByCa: si } = useLazyLoadQuery<SitesQueryResponseType>(SitesQueryRequestType, { oaCode, radius })

	const locations: Marker[] = si.map((site: CareHomesSitesByCaQueryFragment$key) => {
		const siteData = useFragment(CareHomesSitesByCaQueryFragment, site)
		return {
			location: JSON.parse(siteData.location!),
			name: siteData.name,
			id: siteData.id,
		}
	})

	return (
		<Container size="fluid">
			<Row>
				<Col count={4}>
					<Panel height={'auto'}>
						<SubtitleBox text-transform={'none'}>Care Providers</SubtitleBox>
						<Heading level={2}> Lorem ipsum dolor sit amet, consetetur sadipscing elitr. </Heading>
						<VerticalSpace size="xs" />
						<Paragraph>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
							et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
							sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
						</Paragraph>
					</Panel>
					<VerticalSpace size="sm" />
					<Panel padding={8} height={'auto'}>
						<MapFeatures features={featureCollections} locations={locations} />
					</Panel>
				</Col>
				<Col count={8}>
					<Panel padding={16} height={'auto'}>
						<ToggleButtonGroup options={options} activeValue={view} onChange={handleViewChange} />
					</Panel>
					<VerticalSpace size="sm" />
					{view === 'careHomes' ? (
						<>
							<Row>
								<Col count={6}>
									<Panel>
										<CaElderlySitesChart oaCode={oaCode} radius={radius} />
									</Panel>
								</Col>
								<Col count={6}>
									<Panel height={'auto'}>
										<CaElderlyBedsTimeSeriesChart oaCode={oaCode} radius={radius} />
									</Panel>
								</Col>
							</Row>
							<VerticalSpace size="sm" />
							<Row>
								<Col count={12}>
									<Panel>
										<CaSitesTable oaCode={oaCode} radius={radius} />
									</Panel>
								</Col>
							</Row>
						</>
					) : (
						<Panel>
							<CaOperatorsTable oaCode={oaCode} radius={radius} />
						</Panel>
					)}
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<CareHomesProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<CareHomes {...props} />
		</React.Suspense>
	)
}

export { Suspended as CareHomes }
