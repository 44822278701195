/**
 * @generated SignedSource<<05bc0c9622ac9333706be539878c2eff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CareHomesSitesByLadQueryFragment$data = {
  readonly id: any;
  readonly location: string | null | undefined;
  readonly name: string;
  readonly " $fragmentType": "CareHomesSitesByLadQueryFragment";
};
export type CareHomesSitesByLadQueryFragment$key = {
  readonly " $data"?: CareHomesSitesByLadQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CareHomesSitesByLadQueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CareHomesSitesByLadQueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "699df23bc0a2dc0d3f81794a5b10abc8";

export default node;
