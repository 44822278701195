/**
 * @generated SignedSource<<4e472e7d455fade4f7831825c8b6345a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CareFeesQuickFactFragment$data = {
  readonly datasets: ReadonlyArray<{
    readonly code: string;
    readonly values: ReadonlyArray<number>;
  }>;
  readonly labels: ReadonlyArray<string>;
  readonly " $fragmentType": "CareFeesQuickFactFragment";
};
export type CareFeesQuickFactFragment$key = {
  readonly " $data"?: CareFeesQuickFactFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CareFeesQuickFactFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CareFeesQuickFactFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "labels",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CareFeeRateData",
      "kind": "LinkedField",
      "name": "datasets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "values",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CareFeeRates",
  "abstractKey": null
};

(node as any).hash = "0137d89ee2f37e7576edb229ea57d829";

export default node;
