import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	RadiusValues,
	UnemploymentRatesByCaQuery as UerRequestQuery,
} from '../../graphql/queries/__generated__/UnemploymentRatesByCaQuery.graphql'
import { UnemploymentRatesByCaQuery as UerResponseType } from '../../graphql/queries/UnemploymentRatesByCa'
import { Loading } from '../../shared/Loading'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact } from '../QuickFacts'

interface UnemploymentRatesQuickFactProps {
	oaCode: string
	radius: RadiusValues
}

const UnemploymentRatesByCa: React.FC<UnemploymentRatesQuickFactProps> = ({ oaCode, radius }) => {
	const { unemploymentRatesByCa: uer } = useLazyLoadQuery<UerRequestQuery>(UerResponseType, {
		oaCode,
		radius,
	})

	const Ca = uer.datasets[0].values[uer.datasets[0].values.length - 1]
	const national = uer.datasets[uer.datasets.length - 1].values[uer.datasets[0].values.length - 1]

	return (
		<QuickFact
			heading="Unemployment rates"
			value={formatPercentage(Ca)}
			comparison={formatPercentage(national)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<UnemploymentRatesQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading height={75} />}>
			<UnemploymentRatesByCa {...props} />
		</React.Suspense>
	)
}

export { Suspended as UnemploymentRatesByCa }
