import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { UnemploymentRatesByLadCodeQuery as UerRequestQuery } from '../../graphql/queries/__generated__/UnemploymentRatesByLadCodeQuery.graphql'
import { UnemploymentRatesByLadCodeQuery as UerResponseType } from '../../graphql/queries/UnemploymentRatesByLad'
import { Loading } from '../../shared/Loading'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact } from '../QuickFacts'

interface UnemploymentRatesQuickFactProps {
	ladCode: string
}

const UnemploymentRatesByLad: React.FC<UnemploymentRatesQuickFactProps> = ({ ladCode }) => {
	const { unemploymentRatesByLadCode: uer } = useLazyLoadQuery<UerRequestQuery>(UerResponseType, {
		ladCode,
	})

	const lad = uer.datasets[0].values[uer.datasets[0].values.length - 1]
	const national = uer.datasets[uer.datasets.length - 1].values[uer.datasets[0].values.length - 1]

	return (
		<QuickFact
			heading="Unemployment rates"
			value={formatPercentage(lad)}
			comparison={formatPercentage(national)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<UnemploymentRatesQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading height={75} />}>
			<UnemploymentRatesByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as UnemploymentRatesByLad }
