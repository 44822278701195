import * as turf from '@turf/turf'
import { Feature, FeatureCollection, GeoJsonProperties, MultiPolygon, Polygon } from 'geojson'

export type FeatCollection = FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties>

function createCollection(features: Feature<Polygon | MultiPolygon, GeoJsonProperties>[] = []): FeatCollection[] {
	const collections: FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties>[] = [turf.featureCollection(features)]
	return collections
}

function combineCollection(featureCollection: FeatCollection): FeatCollection[] {
	const union: Feature<Polygon | MultiPolygon, GeoJsonProperties> | null = turf.union(featureCollection)
	if (!union) {
		return [turf.featureCollection([])]
	}
	const unionCollection = turf.featureCollection([union])
	return [unionCollection]
}

export { combineCollection, createCollection }
