import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer'
import { flatten } from '@turf/turf'
import { FeatureCollection, GeoJsonProperties, MultiPolygon, Polygon, Position } from 'geojson'

import { theme } from '../../Theme'
import { Marker } from './MapFeatures'

export interface Metric {
	lat: number
	long: number
	value: number
	color?: string
	formatter?: (value: number) => string
}

export interface Styles {
	color?: string
	alpha?: number
}

const defaultAlpha = 0.3

const getStyle = ({ color = '#079669', alpha = defaultAlpha }: Styles) => ({
	clickable: true,
	strokeColor: color,
	strokeOpacity: 0.9,
	strokeWeight: 2,
	fillColor: color,
	fillOpacity: alpha,
})

const getDefaultStyle = (color: string) => getStyle({ color, alpha: defaultAlpha })
const getSelectedStyle = (color: string) => getStyle({ color, alpha: 0 })

function drawFeatures(
	map: google.maps.Map,
	features: FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties>[],
	selectedLad?: string,
) {
	map.data.forEach((feature) => {
		map.data.remove(feature)
	})

	features.forEach((feature) => {
		try {
			map.data.addGeoJson(feature)
		} catch (error) {
			console.error('Error adding feature:', error)
		}
	})

	// Style the features based on their properties
	map.data.setStyle((feature) => {
		const color = feature.getProperty('color') as string
		return selectedLad && feature.getProperty('id') === selectedLad ? getSelectedStyle(color) : getDefaultStyle(color)
	})
}

function onFeatureClick(selectLad: (code: string) => void) {
	return (event: google.maps.Data.MouseEvent) => {
		const ladCode = event.feature.getProperty('id') as string
		selectLad(ladCode)
	}
}

function fitToMapFeature(map: google.maps.Map, feature: google.maps.Data.Feature) {
	const bounds = new google.maps.LatLngBounds()
	const geometry = feature.getGeometry()
	if (geometry) {
		geometry.forEachLatLng((latlng) => {
			bounds.extend(latlng)
		})
	}
	map.fitBounds(bounds)
}

function fitToFeatures(map: google.maps.Map, features: FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties>[]) {
	const bounds = new google.maps.LatLngBounds()
	features.forEach((features) => {
		flatten(features).features.forEach((feature) => {
			feature.geometry.coordinates.flat().forEach((coord: Position) => {
				bounds.extend(new google.maps.LatLng(coord[1], coord[0]))
			})
		})
	})
	map.fitBounds(bounds)
}

function fitToLocations(map: google.maps.Map, locations: Marker[]) {
	const bounds = new google.maps.LatLngBounds()
	locations.forEach((location: Marker) => {
		const { location: l } = location
		const { coordinates } = l
		bounds.extend(new google.maps.LatLng(coordinates[1], coordinates[0]))
	})
	map.fitBounds(bounds)

	// Set a minimum zoom level
	const listener = google.maps.event.addListener(map, 'idle', () => {
		const maxZoom = 15
		const zoom = map.getZoom()
		if (zoom && zoom > maxZoom) map.setZoom(maxZoom)
		google.maps.event.removeListener(listener)
	})
}

function fitToEngland(map: google.maps.Map) {
	const ukBounds = {
		north: 55.0,
		south: 49.96,
		east: 3.5,
		west: -8.62,
	}

	map.fitBounds(
		new google.maps.LatLngBounds(
			new google.maps.LatLng(ukBounds.south, ukBounds.west),
			new google.maps.LatLng(ukBounds.north, ukBounds.east),
		),
	)
}

let circles: google.maps.Marker[] = [] // Array to keep track of circles

function drawMetric(map: google.maps.Map, metric: Metric) {
	const { lat, long: lng, value, color = theme.colors.primary, formatter = (v) => `${v}` } = metric
	// Create a circle
	const circle = new google.maps.Marker({
		map: map,
		position: { lat, lng },
		icon: {
			path: google.maps.SymbolPath.CIRCLE,
			scale: 12, // Adjusted to fit better on the map
			fillColor: '#ffffff',
			fillOpacity: 1, // Circle fill opacity (white with opacity 0.3)
			strokeColor: color,
			strokeWeight: 2, // Circle border width
		},
		label: {
			text: formatter(value),
			color: color,
			fontSize: '9px',
			fontWeight: 'bold',
		},
	})

	// Store the circle for later reference (e.g., for cleanup)
	circles.push(circle)

	return circle
}

// Optionally, add a function to clear existing circles when needed
function clearMetrics() {
	circles.forEach((circle) => circle.setMap(null)) // Remove from the map
	circles.length = 0 // Clear the array
	circles = [] // Reset the array
}

function drawMetrics(map: google.maps.Map, metrics: Metric[]) {
	clearMetrics()
	metrics.forEach((metric) => {
		drawMetric(map, metric)
	})
}

let markerCluster: MarkerClusterer

function drawMarkers(map: google.maps.Map, locations: any[]) {
	const googleMarkers = locations.reduce((acc, local) => {
		if (!local.location) {
			return acc
		}

		const [lng, lat] = local.location.coordinates
		const marker = new google.maps.Marker({
			position: { lat, lng },
			title: local.name,
		})

		// Create an info window for the marker
		const infoWindow = new google.maps.InfoWindow({
			content: `<div>
				<h3>${local.name}</h3>
				<p>Coordinates: ${lat.toFixed(5)}, ${lng.toFixed(5)}</p>
				<p>Address: ${local.postalAddressLine1}, ${local.postalAddressTownCity} ${local.postalCode}</p>
				<p>Number of Beds: ${local.numberOfBeds}</p>
			</div>`,
		})

		// Add a click event listener to the marker
		marker.addListener('click', () => {
			infoWindow.open(map, marker) // Open the info window
		})

		acc.push(marker)

		return acc
	}, [])

	// Remove all existing markers
	markerCluster?.clearMarkers()

	const renderer = {
		render: ({ count, position }: Cluster) =>
			new google.maps.Marker({
				// label: { text: String(count), color: 'white', fontSize: '10px' },
				position,
				// adjust zIndex to be above other markers
				zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
			}),
	}

	// Create a marker clusterer
	markerCluster = new MarkerClusterer({
		map: map,
		markers: googleMarkers,
		renderer,
	})
}

export {
	clearMetrics,
	drawFeatures,
	drawMarkers,
	drawMetrics,
	fitToEngland,
	fitToFeatures,
	fitToLocations,
	fitToMapFeature,
	getDefaultStyle,
	getSelectedStyle,
	getStyle,
	onFeatureClick,
}
