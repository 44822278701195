import React from 'react'

import { SecondaryButton } from '../../shared/Button'
import { SpaceBetween } from '../../shared/layout/Alignment'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { Link, LinkBar } from '../../shared/LinkBar'
import { Panel } from '../../shared/Panel'
import { Select } from '../../shared/Select'

interface TabbarProps {
	category: string
	tab: string
	onTabChange: (key: string) => void
	onCategoryChange: (category: string) => void
	onGenerateReport: () => void
}

interface Navigation {
	label: string
	key: string
	disabled?: boolean
	tabs: Link[]
}

const navigation: Navigation[] = [
	{
		label: 'Elderly Care',
		key: 'elderly-care',
		tabs: [
			{
				label: 'Explorer',
				key: 'explorer',
			},

			{
				label: 'Economy',
				key: 'economic',
			},
			{
				label: 'Population',
				key: 'population',
			},
			{
				label: 'Housing',
				key: 'housing',
			},
			{
				label: 'Elderly',
				key: 'elderly',
			},
			{
				label: 'Dementia',
				key: 'dementia',
			},
			{
				label: 'Care Homes',
				key: 'care-homes',
			},
			{
				label: 'Elderly Care Market',
				key: 'elderly-care-market',
			},
		],
	},
	{
		key: 'vulnerable-care',
		label: 'Vulnerable Care',
		disabled: true,
		tabs: [
			{
				label: 'Explorer',
				key: 'explorer',
			},
			{
				label: 'Learning Disabilities',
				key: 'learning',
			},
			{
				label: 'Physical Disabilities',
				key: 'physical',
			},
			{
				label: 'Supported Living',
				key: 'supported-living',
			},
			{
				label: 'Mental Health Care',
				key: 'mental-health',
			},
			{
				label: 'Rehabilitation centres',
				key: 'rehabilitation',
			},
			{
				label: 'Assisted Living Facilities',
				key: 'assisted-living',
			},
			{
				label: 'Childrens Homes',
				key: 'childrens-homes',
			},
			{
				label: 'Asylum Accommodation',
				key: 'asylum-accommodation',
			},
		],
	},
	{
		key: 'residential',
		label: 'Residential',
		disabled: true,
		tabs: [
			{
				label: 'Explorer',
				key: 'explorer',
			},
			{
				label: 'Mobile Home / Caravan Parks',
				key: 'mobile-home',
			},
			{
				label: 'Student Housing',
				key: 'student-housing',
			},
			{
				label: 'Co-Living Spaces',
				key: 'co-living',
			},
			{
				label: 'Build-to-rent (BTR)',
				key: 'btr',
			},
			{
				label: 'Social Housing',
				key: 'social-housing',
			},
			{
				label: 'Shared Ownership Housing',
				key: 'shared-ownership',
			},
		],
	},
	{
		key: 'commercial',
		label: 'Commercial',
		disabled: true,
		tabs: [
			{
				label: 'Explorer',
				key: 'explorer',
			},
			{
				label: 'Lifestyle Sciences',
				key: 'lifestyle-sciences',
			},
			{
				label: 'Co-Working offices',
				key: 'co-working',
			},
		],
	},
	{
		key: 'retail',
		label: 'Retail',
		disabled: true,
		tabs: [
			{
				label: 'Explorer',
				key: 'explorer',
			},
			{
				label: 'Drive-Thru Restaurants',
				key: 'drive-thru',
			},
			{
				label: 'Petrol Stations',
				key: 'petrol-stations',
			},
			{
				label: 'Retail parks',
				key: 'retail-parks',
			},
			{
				label: 'EV charging hubs',
				key: 'ev-charging',
			},
			{
				label: 'Supermarkets',
				key: 'supermarkets',
			},
			{
				label: 'pubs',
				key: 'pubs',
			},
		],
	},
	{
		key: 'hospitality',
		label: 'Hospitality',
		disabled: true,
		tabs: [
			{
				label: 'Explorer',
				key: 'explorer',
			},
			{
				label: 'Hotels',
				key: 'hotels',
			},
		],
	},
	{
		key: 'industrial',
		label: 'Industrial',
		disabled: true,
		tabs: [
			{
				label: 'Explorer',
				key: 'explorer',
			},
			{
				label: 'Warehouses',
				key: 'warehouses',
			},
			{
				label: 'Distribution Centers',
				key: 'distribution-centers',
			},
			{
				label: 'Flex Spaces',
				key: 'flex-spaces',
			},
			{
				label: 'R&D Facilities',
				key: 'r&d-facilities',
			},
			{
				label: 'Data Centers',
				key: 'data-centers',
			},
			{
				label: 'Self storage',
				key: 'self-storage',
			},
		],
	},

	{
		key: 'educational',
		label: 'Educational',
		disabled: true,
		tabs: [
			{
				label: 'Explorer',
				key: 'explorer',
			},
			{
				label: 'Nursery',
				key: 'nursery',
			},
			{
				label: 'Private School',
				key: 'private-school',
			},
			{
				label: 'State Schools',
				key: 'state-schools',
			},
			{
				label: 'Special Educational Needs Schools',
				key: 'sen-schools',
			},
		],
	},
	{
		key: 'recreational',
		label: 'Recreational',
		disabled: true,
		tabs: [
			{
				label: 'Explorer',
				key: 'explorer',
			},
			{
				label: 'Indoor Sports Facilities',
				key: 'indoor-sports',
			},
			{
				label: 'Gym and fitness centres',
				key: 'gym-fitness',
			},
		],
	},
	{
		key: 'renewable-energy',
		label: 'Renewable Energy',
		disabled: true,
		tabs: [
			{
				label: 'Explorer',
				key: 'explorer',
			},
			{
				label: 'Solar Farms',
				key: 'solar-farms',
			},
			{
				label: 'Wind Farms',
				key: 'wind-farms',
			},
		],
	},
	{
		key: 'prisons',
		label: 'Prisons',
		disabled: true,
		tabs: [
			{
				label: 'Explorer',
				key: 'explorer',
			},
		],
	},
]

const Tabbar: React.FC<TabbarProps> = ({ category, tab, onCategoryChange, onTabChange, onGenerateReport }) => {
	const categories = navigation.map((nav) => ({ label: nav.label, value: nav.key }))
	const currentTab = navigation.find((nav) => nav.key === category)
	const tabs = currentTab?.tabs || []

	return (
		<div style={{ position: 'fixed', left: 0, top: 60, right: 0, zIndex: 1000 }}>
			<Container size="fluid">
				<Row>
					<Col count={12}>
						<Panel padding={8} height="auto">
							<SpaceBetween>
								<Select options={categories} value={category} onChange={onCategoryChange} maxWidth={150} />
								<LinkBar links={tabs} value={tab} onClick={onTabChange} />
								<SecondaryButton onClick={onGenerateReport}>Generate Report</SecondaryButton>
							</SpaceBetween>
						</Panel>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export { Tabbar }
