import { CellContext, flexRender } from '@tanstack/react-table'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DataTable, RowData } from '../DataTable'
import { Paragraph } from '../Text'
import { SitesTableFragment$data } from './__generated__/SitesTableFragment.graphql'

interface SitesTableProps {
	sites: SitesTableFragment$data[]
}

const SitesTable: React.FC<SitesTableProps> = ({ sites }) => {
	const navigate = useNavigate()

	const columns = React.useMemo(
		() => [
			{ header: 'Name', accessorKey: 'name', enableSorting: true },
			{ header: 'City', accessorKey: 'postalAddressTownCity' },
			{ header: 'Operator', accessorKey: 'operatorName' },
			{ header: 'Registration', accessorKey: 'registrationDate' },
			{ header: 'Type', accessorKey: 'serviceTypes' },
			{ header: 'Beds', accessorKey: 'numberOfBeds' },
			{ header: 'Ratings', accessorKey: 'currentRatings' },
			{ header: 'Specialisms', accessorKey: 'specialisms' },
		],
		[],
	)

	const formattedData = sites.map((site) => {
		const { specialisms, serviceTypes, registrationDate } = site
		return {
			...site,
			currentRatings: site.currentRatings ? site.currentRatings.overall.rating : null,
			specialisms: specialisms ? specialisms.map((s: any) => s.name).join(', ') : null,
			serviceTypes: serviceTypes ? serviceTypes.replace(',', ', ') : null,
			registrationDate: registrationDate ? new Date(site.registrationDate).getFullYear().toString() : null,
		}
	})

	const handleRowClick = (id: string) => {
		navigate(`/sites/${id}`)
	}

	const onCell = (cellContext: CellContext<RowData, unknown>) => {
		const cellValue = cellContext.getValue()
		if (!cellValue) {
			return <Paragraph>-</Paragraph>
		}

		return <Paragraph>{flexRender(cellContext.column.columnDef.cell, cellContext)}</Paragraph>
	}

	return <DataTable columns={columns} data={formattedData} onCell={onCell} onRowClick={handleRowClick} />
}

export { SitesTable }
