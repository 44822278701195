import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import {
	PopulationByCaQuery as QueryResponseType,
	RadiusValues,
} from '../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { PopulationByCaQuery as QueryRequestType } from '../../graphql/queries/PopulationByCa'
import { Model } from '../../shared/charts/types'
import { ElderlyPopulationIn10YearsChart } from './ElderlyPopulationIn10YearsChart'

interface CaElderlyPopulationIn10YearsChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaElderlyPopulationIn10YearsChart: React.FC<CaElderlyPopulationIn10YearsChartProps> = ({ oaCode, radius }) => {
	const { populationByCa: pop } = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 65,
		futureOffset: 10,
		includeIntermediates: false,
	})
	const labels = pop.years.map((year) => year.toString())
	const values = [...pop.geographies[0].ageRanges[0].values]
	const model: Model = {
		labels,
		datasets: [
			{
				id: `Catchment Area`,
				values,
			},
		],
	}

	return <ElderlyPopulationIn10YearsChart model={model} />
}

export { CaElderlyPopulationIn10YearsChart }
