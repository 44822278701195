import React from 'react'

import { CaElderlyBedShortagesForecastChart } from '../../../charts/ElderlyBedShortagesForecastChart/CaElderlyBedShortagesForecastChart'
import { RadiusValues } from '../../../graphql/queries/__generated__/AAAwardTypesByCaQuery.graphql'
import { Col, Container, Row } from '../../../shared/layout/Grid'
import { VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { PageTitle, ParagraphBold, SubtitleBox } from '../../../shared/Text'

interface ElderlyCareMarketProps {
	oaCode: string
	radius: RadiusValues
}

const ElderlyCareMarket: React.FC<ElderlyCareMarketProps> = ({ oaCode, radius }) => {
	return (
		<Container size="fluid">
			<Row>
				<Col count={4}>
					<Panel height={'auto'}>
						<SubtitleBox>Elderly Care Market</SubtitleBox>
						<PageTitle>Catchment Area </PageTitle>
						<ParagraphBold>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
							et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
							sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
						</ParagraphBold>
					</Panel>
					<VerticalSpace size="sm" />
					{/* <CaElderlyCareQuickFacts oaCode={oaCode} radius={radius} /> */}
				</Col>
				<Col count={8}>
					<Panel height={'auto'}>
						<VerticalSpace size="sm" />
						<CaElderlyBedShortagesForecastChart oaCode={oaCode} radius={radius} />
						<VerticalSpace size="sm" />
					</Panel>
					<VerticalSpace size="md" />
					<Panel height={'auto'}>Elderly Bed Supply Rate Comparison:Coming Soon</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
		</Container>
	)
}

const Suspended: React.FC<ElderlyCareMarketProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<ElderlyCareMarket {...props} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyCareMarket }
