/**
 * @generated SignedSource<<29ea41073db121862307189115d112b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PopulationByLadCodeQuery$variables = {
  futureOffset: number;
  includeIntermediates?: boolean | null | undefined;
  ladCode: string;
  minAge: number;
  rangeSize: number;
  startYear: number;
};
export type PopulationByLadCodeQuery$data = {
  readonly populationByLadCode: {
    readonly geographies: ReadonlyArray<{
      readonly ageRanges: ReadonlyArray<{
        readonly ageRange: string;
        readonly values: ReadonlyArray<number>;
      }>;
      readonly code: string;
    }>;
    readonly years: ReadonlyArray<number>;
  };
};
export type PopulationByLadCodeQuery = {
  response: PopulationByLadCodeQuery$data;
  variables: PopulationByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "futureOffset"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeIntermediates"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ladCode"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "minAge"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rangeSize"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startYear"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "futureOffset",
        "variableName": "futureOffset"
      },
      {
        "kind": "Variable",
        "name": "includeIntermediates",
        "variableName": "includeIntermediates"
      },
      {
        "kind": "Variable",
        "name": "ladCode",
        "variableName": "ladCode"
      },
      {
        "kind": "Variable",
        "name": "minAge",
        "variableName": "minAge"
      },
      {
        "kind": "Variable",
        "name": "rangeSize",
        "variableName": "rangeSize"
      },
      {
        "kind": "Variable",
        "name": "startYear",
        "variableName": "startYear"
      }
    ],
    "concreteType": "PopulationProjections",
    "kind": "LinkedField",
    "name": "populationByLadCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PopulationGeography",
        "kind": "LinkedField",
        "name": "geographies",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PopulationAgeRange",
            "kind": "LinkedField",
            "name": "ageRanges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ageRange",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "values",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "years",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopulationByLadCodeQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopulationByLadCodeQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "1970c821849cdc2a6354c817994c2794",
    "id": null,
    "metadata": {},
    "name": "PopulationByLadCodeQuery",
    "operationKind": "query",
    "text": "query PopulationByLadCodeQuery(\n  $ladCode: String!\n  $rangeSize: Int!\n  $startYear: Int!\n  $minAge: Int!\n  $futureOffset: Int!\n  $includeIntermediates: Boolean\n) {\n  populationByLadCode(ladCode: $ladCode, rangeSize: $rangeSize, startYear: $startYear, minAge: $minAge, futureOffset: $futureOffset, includeIntermediates: $includeIntermediates) {\n    geographies {\n      code\n      ageRanges {\n        ageRange\n        values\n      }\n    }\n    years\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff1f7ed305bfeb063618a63b6c623c75";

export default node;
