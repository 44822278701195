import graphql from 'babel-plugin-relay/macro'

export const OperatorGroupsQuery = graphql`
	query OperatorGroupsQuery {
		operatorGroups {
			id
			name
			operators {
				id
				name
			}
		}
	}
`
