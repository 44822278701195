import React from 'react'

import { BarChart } from '../../shared/charts/BarChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface HouseholdsWithUniversalCreditChartProps {
	model: Model
}

const HouseholdsWithUniversalCreditChart: React.FC<HouseholdsWithUniversalCreditChartProps> = ({ model }) => {
	return (
		<>
			<Subtitle>Households on Universal Credit</Subtitle>
			<Paragraph>
				The number of households receiving universal credit in the catchment area | Department for Work and Pensions
			</Paragraph>
			<VerticalSpace size="sm" />
			<BarChart horizontal={false} stacked={true} model={model} showLabels={true} alpha={0.9} aspectRatio={1} />
		</>
	)
}

export { HouseholdsWithUniversalCreditChart }
