import graphql from 'babel-plugin-relay/macro'

export const SocialGradesByLadCodeQuery = graphql`
	query SocialGradesByLadCodeQuery($ladCode: String!) {
		socialGradesByLadCode(ladCode: $ladCode) {
			labels
			datasets {
				code
				values
			}
		}
	}
`
