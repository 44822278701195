/**
 * @generated SignedSource<<36955b3a87ce597bb019c8c4019b9982>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RadiusValues = "FIVE" | "ONE" | "TEN" | "THREE" | "%future added value";
export type SocialGradesByCaQuery$variables = {
  oaCode: string;
  radius: RadiusValues;
};
export type SocialGradesByCaQuery$data = {
  readonly socialGradesByCa: {
    readonly datasets: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly labels: ReadonlyArray<string>;
  };
};
export type SocialGradesByCaQuery = {
  response: SocialGradesByCaQuery$data;
  variables: SocialGradesByCaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "oaCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "radius"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "oaCode",
        "variableName": "oaCode"
      },
      {
        "kind": "Variable",
        "name": "radius",
        "variableName": "radius"
      }
    ],
    "concreteType": "SocialGrades",
    "kind": "LinkedField",
    "name": "socialGradesByCa",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labels",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SocialGradesData",
        "kind": "LinkedField",
        "name": "datasets",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "values",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SocialGradesByCaQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SocialGradesByCaQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d1c7aa66c09010ee4105799a21347ce0",
    "id": null,
    "metadata": {},
    "name": "SocialGradesByCaQuery",
    "operationKind": "query",
    "text": "query SocialGradesByCaQuery(\n  $oaCode: String!\n  $radius: RadiusValues!\n) {\n  socialGradesByCa(oaCode: $oaCode, radius: $radius) {\n    labels\n    datasets {\n      code\n      values\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "06598fdf1f2d000e2fefa6a66fbd9242";

export default node;
