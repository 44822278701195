import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { TenureTypesByLadCodeQuery as QueryResponseType } from '../../graphql/queries/__generated__/TenureTypesByLadCodeQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesRequest } from '../../graphql/queries/GeographiesByCodes'
import { TenureTypesByLadCodeQuery } from '../../graphql/queries/TenureTypesByLad'
import { Model } from '../../shared/charts/types'
import { TenureTypePieChart } from './TenureTypePieChart'

interface LadTenureTypesChartProps {
	ladCode: string
}

const filteredTenureTypes = ['Owned', 'Social rented', 'Private rented']

const LadTenureTypesPieChart: React.FC<LadTenureTypesChartProps> = ({ ladCode }) => {
	const { tenureTypesByLadCode: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<QueryResponseType>(
		TenureTypesByLadCodeQuery,
		{ ladCode },
	)

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodesRequest, {
		codes,
	})

	const indexMap = new Map<string, number>()
	filteredTenureTypes.forEach((label) => {
		const index = labels.indexOf(label)
		indexMap.set(label, index)
	})

	const model: Model = {
		labels: filteredTenureTypes,
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: filteredTenureTypes.map((label) => d.values[indexMap.get(label) || 0]),
		})),
	}

	return <TenureTypePieChart model={model} />
}

export { LadTenureTypesPieChart }
