import React from 'react'

import { LineChart } from '../../shared/charts/LineChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface GDHIChartProps {
	model: Model
}

const GDHIChart: React.FC<GDHIChartProps> = ({ model }) => (
	<>
		<Subtitle>Gross Disposable Household Income</Subtitle>
		<Paragraph>
			Gross disposable household income (GDHI) is the amount of money that all of the individuals in the household
			sector have available for spending or saving after they have paid direct and indirect taxes and received any
			direct benefits. GDHI is a concept that is seen to reflect the “material welfare” of the household sector.
		</Paragraph>
		<VerticalSpace size="sm" />
		<LineChart model={model} prefix="£" dataLabelDisplayOption="showOnlyLast" />
	</>
)

export { GDHIChart }
