import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { HourlyWagesByLadCodeQuery as HourlyWagesResponse } from '../../graphql/queries/__generated__/HourlyWagesByLadCodeQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesRequest } from '../../graphql/queries/GeographiesByCodes'
import { HourlyWagesByLadCodeQuery as HourlyWagesRequest } from '../../graphql/queries/HourlyWagesByLad'
import { Model } from '../../shared/charts/types'
import { HourlyWagesChart } from './HourlyWagesChart'

interface LadHourlyWagesChartProps {
	ladCode: string
}

const LadHourlyWagesChart: React.FC<LadHourlyWagesChartProps> = ({ ladCode }) => {
	const { hourlyWagesByLadCode: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<HourlyWagesResponse>(
		HourlyWagesRequest,
		{ ladCode },
	)

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodesRequest, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}

	return <HourlyWagesChart model={model} />
}

export { LadHourlyWagesChart }
