import React from 'react'

import { CaPopulationGrowthByAgeGroupChart } from '../../../charts/PopulationGrowthByAgeGroup/CaPopulationGrowthByAgeGroupChart'
import { CaSocialGradeChart } from '../../../charts/SocialGrade/CaSocialGradeChart'
import { RadiusValues } from '../../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { LeftAligned } from '../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { PageTitle, Paragraph } from '../../../shared/Text'

interface PopulationProps {
	oaCode: string
	radius: RadiusValues
}

const Population: React.FC<PopulationProps> = ({ oaCode, radius }) => {
	return (
		<Container>
			<VerticalSpace size="xl" />
			<Row>
				<LeftAligned>
					<HorizontalSpace size="md" />
					<Col count={7}>
						<PageTitle>Population</PageTitle>
						<Paragraph>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
							et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
							sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
						</Paragraph>
					</Col>
				</LeftAligned>
			</Row>
			<VerticalSpace size="xl" />
			<Row>
				<Col count={6}>
					<Panel>
						<CaPopulationGrowthByAgeGroupChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<CaSocialGradeChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<PopulationProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Population {...props} />
		</React.Suspense>
	)
}

export { Suspended as Population }
