import * as turf from '@turf/turf'
import { Feature, MultiPolygon, Polygon } from 'geojson'
import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import { ElderlyCareStatsQuery as ElderlyCareStatsResponse } from '../../../../graphql/queries/__generated__/ElderlyCareStatsQuery.graphql'
import { LocalAuthorityFeaturesQuery as FeaturesResponseType } from '../../../../graphql/queries/__generated__/LocalAuthorityFeaturesQuery.graphql'
import { ElderlyCareStatsQuery as ElderlyCareStatsRequest } from '../../../../graphql/queries/ElderlyCareStats'
import { LocalAuthorityFeaturesQuery as FeaturesRequestType } from '../../../../graphql/queries/LocalAuthorityFeatures'
import { Loading } from '../../../../shared/Loading'
import { MapSelector } from '../../../../shared/map/MapSelector'
import { Metric } from '../../../../shared/map/utils'
import { theme } from '../../../../Theme'
import { formatter } from '../../../../utils/formatNumber'
import { getBracketIndex } from '../../../../utils/getBracketIndex'
import {
	ElderlyCareStatsFragment$data,
	ElderlyCareStatsFragment$key,
} from './__generated__/ElderlyCareStatsFragment.graphql'
import { ElderlyCareStatsFragment } from './ElderlyCareStatsFragment'

interface MapProps {
	ladCode: string
	showMarkers: boolean
	activeFilter: string
}

const Map: React.FC<MapProps> = ({ ladCode, showMarkers, activeFilter }) => {
	const navigate = useNavigate()
	// Using useLazyLoadQuery to fetch data
	const data = useLazyLoadQuery<ElderlyCareStatsResponse>(ElderlyCareStatsRequest, {})
	const { localAuthorityFeatures } = useLazyLoadQuery<FeaturesResponseType>(FeaturesRequestType, {
		version: 'bsc',
	})

	const allFeatures: Feature<Polygon | MultiPolygon, LaFeatureProperties>[] =
		(localAuthorityFeatures as Feature<Polygon | MultiPolygon, LaFeatureProperties>[]) || []

	const tableData = React.useMemo(() => {
		return data.elderlyCareStats || []
	}, [data])

	const sites = tableData.map((site: ElderlyCareStatsFragment$key) => {
		return useFragment(ElderlyCareStatsFragment, site)
	})

	const { metrics: metricData, metricFeatures } = bedSupplyRate(allFeatures, sites)
	let metrics: Metric[] = []
	if (!showMarkers) {
		metrics = []
	} else {
		metrics = metricData
	}

	const featureCollection = activeFilter ? turf.featureCollection(allFeatures) : turf.featureCollection(metricFeatures)
	const featureCollections = [featureCollection]

	// Update URL and state with startTransition
	const handleSelectChange = (selectedValue: string) => {
		navigate(`/lads/${selectedValue}`)
	}

	return (
		<MapSelector
			localAuthorityFeatures={featureCollections}
			selectedLadCode={ladCode}
			selectLad={handleSelectChange}
			metrics={metrics}
		/>
	)
}

type LaFeatureProperties = {
	id: string
	lat: number
	long: number
	name: string
	color?: string
	value?: number
}

function bedSupplyRate(
	features: Feature<Polygon | MultiPolygon, LaFeatureProperties>[],
	ecs: ElderlyCareStatsFragment$data[],
): { metricFeatures: Feature<Polygon | MultiPolygon, LaFeatureProperties>[]; metrics: Metric[] } {
	const ladBedSupplyMap: { [key: string]: number } = {}
	let highestBedSupplyRateCount = 0

	// Map the number of beds per LAD
	ecs.forEach((stat) => {
		const ladCode = stat.ladCode!
		const bedSupply = (stat.numberOfBeds / stat.populationOver65) * 100

		if (ladBedSupplyMap[ladCode]) {
			ladBedSupplyMap[ladCode] += bedSupply
		} else {
			ladBedSupplyMap[ladCode] = bedSupply
		}

		if (ladBedSupplyMap[ladCode] > highestBedSupplyRateCount) {
			highestBedSupplyRateCount = ladBedSupplyMap[ladCode]
		}
	})

	const colors = [...theme.colors.severity].reverse()
	const colorFunc = getBracketIndex(0, highestBedSupplyRateCount, colors.length)

	const metrics: Metric[] = []

	// Map over features and create new features with updated properties
	const metricFeatures = features.map((feature) => {
		const { properties } = feature
		const { id, lat, long } = properties
		const value = ladBedSupplyMap[id] || 0
		const color = colors[colorFunc(value)]

		// Clone the feature and its properties, then add the color
		const metricFeature: Feature<Polygon | MultiPolygon, LaFeatureProperties> = {
			...feature, // Spread the original feature
			properties: {
				...properties, // Clone the properties object
				color, // Add the color property
				value, // Add the value property
			},
		}

		// Only add to metrics if value > 0
		if (value > 0) {
			metrics.push({
				value,
				lat,
				long,
				color,
				formatter: (v) => formatter('', '%')(v.toFixed(1)),
			})
		}

		return metricFeature
	})

	// Return both the updated features and the metrics array
	return {
		metricFeatures,
		metrics,
	}
}

const Suspended: React.FC<MapProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Map {...props} />
		</React.Suspense>
	)
}

export { Suspended as Map }
