import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { awardTypesLabelMap } from '../../Constants'
import {
	AAAwardTypesByCaQuery as QueryResponseType,
	RadiusValues,
} from '../../graphql/queries/__generated__/AAAwardTypesByCaQuery.graphql'
import { AAAwardTypesByCaQuery as QueryRequestType } from '../../graphql/queries/AAAwardTypesByCa'
import { Model } from '../../shared/charts/types'
import { ElderlyCareNeedsChart } from './ElderlyCareNeedsChart'

interface CaElderlyCareNeedsChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaElderlyCareNeedsChart: React.FC<CaElderlyCareNeedsChartProps> = ({ oaCode, radius }) => {
	const { aaAwardTypesByCa: aa } = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		oaCode,
		radius,
	})

	const model: Model = {
		labels: [...aa.labels],
		datasets: [
			{
				id: awardTypesLabelMap.higherRate,
				values: [...aa.higherRate[0].values],
			},
			{
				id: awardTypesLabelMap.lowerRate,
				values: [...aa.lowerRate[0].values],
			},
		],
	}

	return <ElderlyCareNeedsChart model={model} />
}

export { CaElderlyCareNeedsChart }
