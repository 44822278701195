export type SitesData = {
	registrationDate?: string | null
	numberOfBeds?: number | null
}

function calculateBedSupplyData(sites: SitesData[], yearsOfInterest: number[]): { [year: number]: number } {
	if (!sites || !Array.isArray(sites)) {
		return {}
	}

	// Initialize yearBedMap for years of interest
	return sites.reduce(
		(acc, site) => {
			const registrationDate = site.registrationDate
			if (!registrationDate) {
				return acc
			}

			const registrationYear = new Date(registrationDate).getFullYear()

			// Add beds to each year from registration year onwards within years of interest
			yearsOfInterest.forEach((year) => {
				if (registrationYear <= year && site.numberOfBeds) {
					acc[year] = (acc[year] || 0) + (site.numberOfBeds || 0)
				}
			})

			return acc
		},
		{} as { [year: number]: number },
	)
}
export { calculateBedSupplyData }
