import { AAAwardTypesByLadCodeQuery$data } from '../../graphql/queries/__generated__/AAAwardTypesByLadCodeQuery.graphql'
import { calculateBedDemandData } from '../../utils/calculateBedDemand'
import { calculateBedSupplyData, SitesData } from '../../utils/calculateBedSupplyData'
import { forecastData } from '../../utils/forecastData'
import { ElderlyBedShortagesForecastChartSiteFragment$data } from './__generated__/ElderlyBedShortagesForecastChartSiteFragment.graphql'

function getBedSupplyAndDemandHistoricalForecast(
	aaData: AAAwardTypesByLadCodeQuery$data['aaAwardTypesByLadCode'],
	sitesData: ElderlyBedShortagesForecastChartSiteFragment$data[],
	displayStartYear: number,
	displayEndYear: number,
) {
	const sites: SitesData[] = sitesData.map((site) => ({
		registrationDate: site.registrationDate,
		numberOfBeds: site.numberOfBeds,
	}))

	// Define historical and forecast years
	const bedSupplyHistoricalYears = [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
	const bedSupplyForecastYears = [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032]

	const bedDemandHistoricalYears = [2019, 2020, 2021, 2022, 2023, 2024]
	const bedDemandForecastYears = [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032]

	// Process Bed Demand Data
	const bedDemandHistorical = calculateBedDemandData(aaData, bedDemandHistoricalYears)
	const bedDemandForecast = forecastData(bedDemandHistorical, bedDemandForecastYears)
	const bedDemandCombined = { ...bedDemandHistorical, ...bedDemandForecast }

	// Process Bed Supply Data
	const bedSupplyHistorical = calculateBedSupplyData(sites, bedSupplyHistoricalYears)
	const bedSupplyForecast = forecastData(bedSupplyHistorical, bedSupplyForecastYears)
	const bedSupplyCombined = { ...bedSupplyHistorical, ...bedSupplyForecast }

	// Combine all years from both datasets
	const allYearsSet = new Set<number>([
		...bedSupplyHistoricalYears,
		...bedSupplyForecastYears,
		...bedDemandHistoricalYears,
		...bedDemandForecastYears,
	])
	const allYears = Array.from(allYearsSet).sort((a, b) => a - b)

	// Calculate Bed Shortage (Supply - Demand)
	const bedShortageCombined: { [year: number]: number } = {}
	allYears.forEach((year) => {
		const supply = bedSupplyCombined[year] || 0
		const demand = bedDemandCombined[year] || 0
		bedShortageCombined[year] = supply - demand
	})

	// Determine which years are forecasted
	const forecastYearSet = new Set<number>([...bedSupplyForecastYears, ...bedDemandForecastYears])

	// Apply display range filter
	const filteredYears = allYears.filter((year) => year >= displayStartYear && year <= displayEndYear)

	// Prepare labels with 'E' prefix for forecasted years
	const labels = filteredYears.map((year) => (forecastYearSet.has(year) ? `E${year}` : `${year}`))

	// Prepare datasets
	const datasets = [
		{
			id: 'Bed Demand',
			values: filteredYears.map((year) => bedDemandCombined[year] || null),
		},
		{
			id: 'Bed Supply',
			values: filteredYears.map((year) => bedSupplyCombined[year] || null),
		},
		{
			id: 'Bed Shortage',
			values: filteredYears.map((year) => bedShortageCombined[year] || null),
		},
	]

	return {
		labels,
		datasets,
	}
}

export { getBedSupplyAndDemandHistoricalForecast }
