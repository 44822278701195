import React from 'react'

import { PieChart } from '../../shared/charts/PieChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface SocialGradeChartProps {
	model: Model
}

const SocialGradeChart: React.FC<SocialGradeChartProps> = ({ model }) => {
	return (
		<>
			<Subtitle>Social Grade Distribution</Subtitle>
			<Paragraph>
				Socio-economic classification of catchment area population based on the social and financial situation | Census
				2021
			</Paragraph>
			<VerticalSpace size="sm" />
			<PieChart model={model} aspectRatio={1} />
		</>
	)
}

export { SocialGradeChart }
