import React from 'react'

interface ContainerProps {
	children: React.ReactNode
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fluid'
	removePadding?: boolean
}

const Container: React.FC<ContainerProps> = ({ children, size, removePadding = false }) => {
	const sizeClass = size ? `container-${size}` : 'container'
	const paddingClass = removePadding ? 'px-0' : ''
	return <div className={`${sizeClass} ${paddingClass}`}>{children}</div>
}

interface RowProps {
	children: React.ReactNode
}

const Row: React.FC<RowProps> = ({ children }) => {
	return (
		<div className="row" style={{ alignItems: 'stretch' }}>
			{children}
		</div>
	)
}

interface ColProps {
	children?: React.ReactNode
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
	count?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

const Col: React.FC<ColProps> = ({ children, size, count }) => {
	let className = 'col'
	if (size) className += `-${size}`
	if (count) className += `-${count}`
	return <div className={className}>{children}</div>
}

export { Col, Container, Row }
