import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { FeatureCollection, GeoJsonProperties, MultiPolygon, Polygon } from 'geojson'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { getGoogleMapsParams } from '../../Config'
import {
	drawFeatures,
	drawMetrics,
	fitToEngland,
	fitToMapFeature,
	getDefaultStyle,
	getSelectedStyle,
	Metric,
	onFeatureClick,
} from './utils'

// Define the props interface
interface MapSelectorProps {
	localAuthorityFeatures: FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties>[]
	metrics: Metric[]
	selectedLadCode?: string
	selectLad: (ladCode: string) => void
}

// Define the map container style and initial center
const containerStyle = {
	width: '100%',
	height: '70vh',
}

const initialMapOptions = {
	zoom: 7,
	center: { lat: 53.48, lng: -2.24 },
}

const gm = getGoogleMapsParams()

export const MapSelector: FC<MapSelectorProps> = ({ localAuthorityFeatures, metrics, selectedLadCode, selectLad }) => {
	const [map, setMap] = useState<google.maps.Map | undefined>(undefined)
	const featuresDrawn = useRef(false)
	const metricsDrawn = useRef(false)

	// Load the Google Maps API
	const { isLoaded } = useJsApiLoader(gm)

	useEffect(() => {
		if (map && localAuthorityFeatures.length > 0 && !featuresDrawn.current) {
			drawFeatures(map, localAuthorityFeatures, selectedLadCode)
			map.data.addListener(
				'click',
				onFeatureClick(selectLad), // Adjusted to use props
			)
			// featuresDrawn.current = true
		}
	}, [map, localAuthorityFeatures, selectLad, metrics])

	useEffect(() => {
		if (map && metrics && !metricsDrawn.current) {
			drawMetrics(map, metrics)
			// metricsDrawn.current = true
		}
	}, [map, metrics, localAuthorityFeatures])

	useEffect(() => {
		if (map && selectedLadCode) {
			map.data.setStyle((feature) => {
				const color = feature.getProperty('color') as string
				if (feature.getProperty('id') === selectedLadCode) {
					fitToMapFeature(map, feature)
					return getSelectedStyle(color)
				} else {
					return getDefaultStyle(color)
				}
			})
		}
		if (map && selectedLadCode === '') {
			fitToEngland(map)
		}
	}, [map, selectedLadCode])

	const onLoad = useCallback((map: google.maps.Map) => {
		setMap(map)
		fitToEngland(map)
	}, [])

	const onUnmount = useCallback(() => {
		setMap(undefined)
	}, [])

	return (
		<div style={containerStyle}>
			{isLoaded ? (
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={initialMapOptions.center}
					zoom={initialMapOptions.zoom}
					onLoad={onLoad}
					onUnmount={onUnmount}
				>
					{/* Additional map elements can be added here */}
				</GoogleMap>
			) : (
				<></>
			)}
		</div>
	)
}
