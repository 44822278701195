import React from 'react'

import { CaHousePricesChart } from '../../../charts/HousePrices/CaHousePricesChart'
import { CaHousingYearsToBuyChart } from '../../../charts/HousingYearsToBuy/CaHousingYearsToBuyChart'
import { CaTenureTypesPieChart } from '../../../charts/TenureTypes/CaTenureTypesPieChart'
import { RadiusValues } from '../../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { LeftAligned } from '../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { PageTitle, Paragraph } from '../../../shared/Text'

interface HousingProps {
	oaCode: string
	radius: RadiusValues
}

const Housing: React.FC<HousingProps> = ({ oaCode, radius }) => {
	return (
		<Container>
			<VerticalSpace size="xl" />
			<Row>
				<LeftAligned>
					<HorizontalSpace size="md" />
					<Col count={7}>
						<PageTitle>Housing</PageTitle>
						<Paragraph>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
							et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
							sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
						</Paragraph>
					</Col>
				</LeftAligned>
			</Row>
			<VerticalSpace size="xl" />
			<Row>
				<Col count={6}>
					<Panel>
						<CaHousePricesChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<CaHousingYearsToBuyChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={6}>
					<Panel>
						<CaTenureTypesPieChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<HousingProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Housing {...props} />
		</React.Suspense>
	)
}

export { Suspended as Housing }
