import * as turf from '@turf/turf'
import { Feature, FeatureCollection, GeoJsonProperties, MultiPolygon, Polygon } from 'geojson'
import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { milesRadiusValueInMeters } from '../../../../Constants'
import { LocalAuthoritiesByLadsQuery as LaQueryResponseType } from '../../../../graphql/queries/__generated__/LocalAuthoritiesByLadsQuery.graphql'
import {
	OutputAreasByMileRadiusQuery as AreaQueryResponseType,
	RadiusValues,
} from '../../../../graphql/queries/__generated__/OutputAreasByMileRadiusQuery.graphql'
import { localAuthoritiesByLadsQuery as LaQueryRequestType } from '../../../../graphql/queries/LocalAuthoritiesByLads'
import { OutputAreasByMileRadiusQuery as AreaQueryRequestType } from '../../../../graphql/queries/OutputAreasByMileRadius'
import { MapFeatures, Marker } from '../../../../shared/map/MapFeatures'
import { theme } from '../../../../Theme'
import { combineCollection, createCollection } from '../../../../utils/featureCollections'
import { CaptureAreaFragment$key } from '../../__generated__/CaptureAreaFragment.graphql'
import { CaptureAreaFragment } from '../../CaptureAreaFragment'

export type ViewTypes = 'circle' | 'oas' | 'ca'

interface MapProps {
	lat: number
	long: number
	radius: RadiusValues
	viewType: ViewTypes
	showingLads: boolean
	selectingLatLong: boolean
	onMapClick: (lat: string, lng: string) => void
}

const Map: React.FC<MapProps> = ({ lat, long, radius, viewType, showingLads, selectingLatLong, onMapClick }) => {
	const { outputAreasByMileRadius } = useLazyLoadQuery<AreaQueryResponseType>(AreaQueryRequestType, {
		lat: `${lat}`,
		long: `${long}`,
		radius,
	})
	const ca = useFragment<CaptureAreaFragment$key>(CaptureAreaFragment, outputAreasByMileRadius)

	const { localAuthoritiesByLadCodes: las } = useLazyLoadQuery<LaQueryResponseType>(LaQueryRequestType, {
		ladCodes: ca.ladCodes,
	})

	const oaFeatures: Feature<Polygon | MultiPolygon>[] = ca.outputAreas.map((o) => {
		const geometry = JSON.parse(o.geometry!)
		return {
			type: 'Feature',
			geometry,
			properties: {
				// color: theme.colors.primary,
				alpha: 0,
			},
		}
	})

	const collections = createCollection()

	if (!selectingLatLong) {
		// A collection of all the output areas
		const oaCollection: FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties> =
			turf.featureCollection(oaFeatures)

		// If we are making a 'ca'
		if (viewType === 'ca') {
			const unionCollection = combineCollection(oaCollection)
			unionCollection.map((c) => collections.push(c))
		}
		// if we are making a 'ca' and showing the output areas
		else if (viewType === 'oas') {
			collections.push(oaCollection)
		}
		// if we are making a 'circle'
		else {
			const metres = milesRadiusValueInMeters[radius]

			const circle = turf.circle([long, lat], metres, {
				steps: 64,
				units: 'metres',
				properties: {
					// color: theme.colors.primary,
					alpha: 0.5,
				},
			})

			const circleFeature: Feature<Polygon> = {
				type: 'Feature',
				geometry: circle.geometry,
				properties: circle.properties,
			}
			const circleFeatureCollection: FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties> =
				turf.featureCollection([circleFeature])
			collections.push(circleFeatureCollection)
		}

		// Add local authorities to the feature collection if showing
		if (showingLads) {
			const ladFeatures: Feature<Polygon | MultiPolygon>[] = las.map((la, i) => {
				const geometry = JSON.parse(la.geometry!)
				return {
					type: 'Feature',
					geometry,
					properties: {
						color: theme.colors.colorPalette[i + 1],
						alpha: 0.2,
					},
				}
			})
			const ladCollection = turf.featureCollection(ladFeatures)
			collections.push(ladCollection)
		}
	}

	const markers: Marker[] = [
		{
			location: {
				coordinates: [long, lat],
				type: 'Point',
			},
			name: 'Output Area',
			id: '1',
		},
	]

	return <MapFeatures features={collections} locations={markers} onMapClick={onMapClick} />
}

export { Map }
