import React from 'react'

export interface Option {
	value: string
	label: string
	disabled?: boolean
}

interface SelectProps {
	options: Option[]
	onChange?: (value: string) => void
	value?: string
	maxWidth?: number
}

const Select: React.FC<SelectProps> = ({ options, onChange, value, maxWidth = 300 }) => {
	return (
		<div className="input-group" style={{ maxWidth }}>
			<select className="form-select" value={value} onChange={(e) => onChange && onChange(e.target.value)}>
				{options.map((option) => (
					<option key={option.value} value={option.value} disabled={option.disabled}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	)
}

export { Select }
