// theme.ts

function getBootstrapVar(key: string): string {
	return getComputedStyle(document.documentElement).getPropertyValue(`--bs-${key}`).trim()
}

const primary = getBootstrapVar('primary')
const secondary = getBootstrapVar('secondary')
const success = getBootstrapVar('success')
const info = getBootstrapVar('info')
const warning = getBootstrapVar('warning')
const danger = getBootstrapVar('danger')
const lightText = getBootstrapVar('light')
const darkText = getBootstrapVar('dark')

export const theme = {
	colors: {
		primary,
		secondary,
		darkText,
		lightText,
		success,
		info,
		warning,
		danger,
		canvas: '#F6F6F6',
		primarySurface: '#FFFFFF',
		secondarySurface: '#F2F2F2',
		grey: '#8F8F8F',
		red: 'rgb(241, 84, 110)',
		colorPalette: [
			'rgb(6, 170, 91)',
			'rgb(49, 150, 237)',
			'rgb(247, 181, 1)',
			'rgb(37, 170, 164)',
			'rgb(157, 83, 242)',
			'rgb(241, 84, 110)',
		],
		colorPaletteAlpha: [
			(a: number) => `rgba(6, 170, 91, ${a})`,
			(a: number) => `rgba(49, 150, 237, ${a})`,
			(a: number) => `rgba(247, 181, 1, ${a})`,
			(a: number) => `rgba(37, 170, 164, ${a})`,
			(a: number) => `rgba(157, 83, 242, ${a})`,
			(a: number) => `rgba(241, 84, 110, ${a})`,
		],
		severity: ['rgb(138, 201, 38)', 'rgb(197, 202, 48)', 'rgb(255, 202, 58)', 'rgb(255, 146, 76)', 'rgb(255, 89, 94)'],
		rainbow: [
			'rgb(255, 89, 94)',
			'rgb(255, 146, 76)',
			'rgb(255, 202, 58)',
			'rgb(197, 202, 48)',
			'rgb(138, 201, 38)',
			'rgb(82, 166, 117)',
			'rgb(25, 130, 196)',
			'rgb(66, 103, 172)',
			'rgb(106, 76, 147)',
			'rgb(181, 166, 201)',
		],
		rainbowAlpha: [
			(a: number) => `rgb(255, 89, 94, ${a})`,
			(a: number) => `rgb(255, 146, 76, ${a})`,
			(a: number) => `rgb(255, 202, 58, ${a})`,
			(a: number) => `rgb(197, 202, 48, ${a})`,
			(a: number) => `rgb(138, 201, 38, ${a})`,
			(a: number) => `rgb(82, 166, 117, ${a})`,
			(a: number) => `rgb(25, 130, 196, ${a})`,
			(a: number) => `rgb(66, 103, 172, ${a})`,
			(a: number) => `rgb(106, 76, 147, ${a})`,
			(a: number) => `rgb(181, 166, 201, ${a})`,
		],
	},
}
