function linearRegression(x: number[], y: number[]) {
	const n = x.length
	const sumX = x.reduce((a, b) => a + b, 0)
	const sumY = y.reduce((a, b) => a + b, 0)
	const sumXY = x.reduce((sum, xi, idx) => sum + xi * y[idx], 0)
	const sumX2 = x.reduce((sum, xi) => sum + xi * xi, 0)

	const denominator = n * sumX2 - sumX * sumX
	if (denominator === 0) {
		throw new Error('Denominator in linear regression calculation is zero.')
	}

	const slope = (n * sumXY - sumX * sumY) / denominator
	const intercept = (sumY - slope * sumX) / n

	return { slope, intercept }
}
export { linearRegression }
