import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import {
	GrossDisposableHouseholdIncomesByCaQuery as GDHIResponse,
	RadiusValues,
} from '../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByCaQuery.graphql'
import { HousePricesByCaQuery as HousePricesResponse } from '../../graphql/queries/__generated__/HousePricesByCaQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesRequest } from '../../graphql/queries/GeographiesByCodes'
import { GrossDisposableHouseholdIncomesByCaQuery as GDHIRequest } from '../../graphql/queries/GrossDisposableHouseholdIncomesByCa'
import { HousePricesByCaQuery as HousePricesRequest } from '../../graphql/queries/HousePricesByCa'
import { calculateYearsToBuy } from '../../utils/calculateYearsToBuy'
import { HousingYearsToBuyChart } from './HousingYearsToBuyChart'

interface CaHousingYearsToBuyChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaHousingYearsToBuyChart: React.FC<CaHousingYearsToBuyChartProps> = ({ oaCode, radius }) => {
	const { housePricesByCa: hp } = useLazyLoadQuery<HousePricesResponse>(HousePricesRequest, { oaCode, radius })

	const { grossDisposableHouseholdIncomesByCa: gdhi } = useLazyLoadQuery<GDHIResponse>(GDHIRequest, {
		oaCode,
		radius,
	})

	const codes = hp.datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodesRequest, {
		codes,
	})

	const model = calculateYearsToBuy(hp, gdhi, geos)

	return <HousingYearsToBuyChart model={model} />
}

export { CaHousingYearsToBuyChart }
