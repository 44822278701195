import graphql from 'babel-plugin-relay/macro'

export const PromptQuery = graphql`
	query PromptQuery($content: String!, $key: String!, $version: OpenAiVersions!, $temperature: Float) {
		prompt(content: $content, key: $key, version: $version, temperature: $temperature) {
			response
			version
		}
	}
`
