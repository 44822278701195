import graphql from 'babel-plugin-relay/macro'

export const CareFeesQuickFactFragment = graphql`
	fragment CareFeesQuickFactFragment on CareFeeRates {
		labels
		datasets {
			code
			values
		}
	}
`
