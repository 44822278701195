import React from 'react'

import { Col, Row } from '../../../shared/layout/Grid'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { LaSitesTable } from '../../../shared/sitesTable/LaSitesTable'

interface CareHomesProps {
	ladCode: string
}

const CareHomes: React.FC<CareHomesProps> = ({ ladCode }) => {
	return (
		<Row>
			<Col count={12}>
				<Panel>
					<LaSitesTable ladCode={ladCode} />
				</Panel>
			</Col>
		</Row>
	)
}

const Suspended: React.FC<CareHomesProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<CareHomes ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as CompetitiveAnalysis }
