import graphql from 'babel-plugin-relay/macro'

export const TenureTypesByCaQuery = graphql`
	query TenureTypesByCaQuery($oaCode: String!, $radius: RadiusValues!) {
		tenureTypesByCa(oaCode: $oaCode, radius: $radius) {
			labels
			datasets {
				code
				values
			}
		}
	}
`
