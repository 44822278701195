import graphql from 'babel-plugin-relay/macro'

export const OperatorsQuery = graphql`
	query OperatorsQuery($limit: Int!, $offset: Int!) {
		operators(limit: $limit, offset: $offset) {
			limit
			offset
			hasNextPage
			operators {
				...OperatorsOperatorFragment
			}
		}
	}
`
