import { Dataset } from '../shared/charts/types'

function transformToMarkdownTable(chartData: { labels: string[]; datasets: Dataset[] }): string {
	const { labels, datasets } = chartData

	// Define the table header
	const header = ['Year', ...datasets.map((ds) => ds.id)]
	// Initialize the Markdown table string
	let markdown = `| ${header.join(' | ')} |\n`
	markdown += `| ${header.map(() => '---').join(' | ')} |\n`

	// Iterate over each label (year) to create table rows
	labels.forEach((year, index) => {
		const row = [year]
		datasets.forEach((ds) => {
			const value = ds.values[index]
			// Convert number to string, or use 'N/A' if value is null or undefined
			row.push(value !== undefined && value !== null ? value.toString() : 'N/A')
		})
		markdown += `| ${row.join(' | ')} |\n`
	})

	return markdown
}

export { transformToMarkdownTable }
