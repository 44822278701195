import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { RadiusValues, SitesByCaQuery } from '../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { SitesByCaQuery as QueryRequestType } from '../../graphql/queries/SitesByCa'
import {
	ElderlyBedsTimeSeriesChartSiteFragment$data,
	ElderlyBedsTimeSeriesChartSiteFragment$key,
} from './__generated__/ElderlyBedsTimeSeriesChartSiteFragment.graphql'
import { ElderlyBedsTimeSeriesChart } from './ElderlyBedsTimeSeriesChart'
import { ElderlyBedsTimeSeriesChartSiteFragment } from './ElderlyBedsTimeSeriesChartSiteFragment'
import { getElderlyBeds } from './helpers'

interface ElderlyBedsTimeSeriesChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaElderlyBedsTimeSeriesChart: React.FC<ElderlyBedsTimeSeriesChartProps> = ({ oaCode, radius }) => {
	const sitesData = useLazyLoadQuery<SitesByCaQuery>(QueryRequestType, {
		oaCode,
		radius,
	})

	const sites = sitesData.sitesByCa.map((site: ElderlyBedsTimeSeriesChartSiteFragment$key) => {
		return useFragment<ElderlyBedsTimeSeriesChartSiteFragment$key>(ElderlyBedsTimeSeriesChartSiteFragment, site)
	}) as ElderlyBedsTimeSeriesChartSiteFragment$data[]

	const model = getElderlyBeds(sites)

	return <ElderlyBedsTimeSeriesChart model={model} />
}

export { CaElderlyBedsTimeSeriesChart }
