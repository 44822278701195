import * as turf from '@turf/turf'
import { Feature, MultiPolygon, Polygon } from 'geojson'
import React, { useState } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { LadElderlyBedsTimeSeriesChart } from '../../../../charts/ElderlyBedsTimeSeriesChart/LadElderlyBedsTimeSeriesChart'
import { LadElderlySitesChart } from '../../../../charts/ElderlySitesChart/LadElderlySitesChart'
import { LocalAuthorityByLadCodeQuery as LaQueryResponseType } from '../../../../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { SitesByLadCodeQuery as SitesQueryResponseType } from '../../../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { LocalAuthorityByLadCodeQuery as LaQueryRequestType } from '../../../../graphql/queries/LocalAuthorityByLad'
import { SitesByLadCodeQuery as SitesQueryRequestType } from '../../../../graphql/queries/SitesByLadCode'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../shared/layout/Space'
import { Loading } from '../../../../shared/Loading'
import { MapFeatures, Marker } from '../../../../shared/map/MapFeatures'
import { LaOperatorsTable } from '../../../../shared/operatorsTable/LaOperatorsTable'
import { Panel } from '../../../../shared/Panel'
import { LaSitesTable } from '../../../../shared/sitesTable/LaSitesTable'
import { Heading, Paragraph, SubtitleBox } from '../../../../shared/Text'
import { ToggleButtonGroup } from '../../../../shared/ToggleButtonGroup'
import { CareHomesSitesByLadQueryFragment$key } from './__generated__/CareHomesSitesByLadQueryFragment.graphql'
import { CareHomesSitesByLadQueryFragment } from './CareHomesSitesByLad'

interface CareHomesProps {
	ladCode: string
}

const CareHomes: React.FC<CareHomesProps> = ({ ladCode }) => {
	const [view, setView] = useState('careHomes')

	const options = [
		{ label: 'Care Homes', value: 'careHomes' },
		{ label: 'Operators', value: 'operators' },
	]

	const handleViewChange = (value: string) => {
		setView(value)
	}

	const { localAuthorityByLadCode: la } = useLazyLoadQuery<LaQueryResponseType>(LaQueryRequestType, { ladCode })
	const { sitesByLadCode: si } = useLazyLoadQuery<SitesQueryResponseType>(SitesQueryRequestType, { ladCode })

	const locations: Marker[] = si.map((site: CareHomesSitesByLadQueryFragment$key) => {
		const siteData = useFragment(CareHomesSitesByLadQueryFragment, site)
		return {
			location: JSON.parse(siteData.location!),
			name: siteData.name,
			id: siteData.id,
		}
	})

	const { geometry } = la

	const feature: Feature<Polygon | MultiPolygon> = {
		type: 'Feature',
		geometry: JSON.parse(geometry),
		properties: {},
	}

	const featureCollection = turf.featureCollection([feature])
	const featureCollections = [featureCollection]

	return (
		<Container size="fluid">
			<Row>
				<Col count={4}>
					<Panel height={'auto'}>
						<SubtitleBox text-transform={'none'}>Care Providers</SubtitleBox>
						<Heading level={2}> Lorem ipsum dolor sit amet, consetetur sadipscing elitr. </Heading>
						<VerticalSpace size="xs" />
						<Paragraph>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
							et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
							sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
						</Paragraph>
					</Panel>
					<VerticalSpace size="sm" />
					<Panel padding={8} height={'auto'}>
						<MapFeatures features={featureCollections} locations={locations} />
					</Panel>
				</Col>
				<Col count={8}>
					<Panel padding={16} height={'auto'}>
						<ToggleButtonGroup options={options} activeValue={view} onChange={handleViewChange} />
					</Panel>
					<VerticalSpace size="sm" />
					{view === 'careHomes' ? (
						<>
							<Row>
								<Col count={6}>
									<Panel>
										<LadElderlySitesChart ladCode={ladCode} />
									</Panel>
								</Col>
								<Col count={6}>
									<Panel height={'auto'}>
										<LadElderlyBedsTimeSeriesChart ladCode={ladCode} />
									</Panel>
								</Col>
							</Row>
							<VerticalSpace size="sm" />
							<Row>
								<Col count={12}>
									<Panel>
										<LaSitesTable ladCode={ladCode} />
									</Panel>
								</Col>
							</Row>
						</>
					) : (
						<Panel>
							<LaOperatorsTable ladCode={ladCode} />
						</Panel>
					)}
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<CareHomesProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<CareHomes ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as CareHomes }
