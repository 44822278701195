import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { UnemploymentRatesByLadCodeQuery as UnemploymentRatesResponse } from '../../graphql/queries/__generated__/UnemploymentRatesByLadCodeQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesRequest } from '../../graphql/queries/GeographiesByCodes'
import { UnemploymentRatesByLadCodeQuery as UnemploymentRatesRequest } from '../../graphql/queries/UnemploymentRatesByLad'
import { Model } from '../../shared/charts/types'
import { UnemploymentRateChart } from './UnemploymentRateChart'

interface LadUnemploymentRateChartProps {
	ladCode: string
}

const LadUnemploymentRateChart: React.FC<LadUnemploymentRateChartProps> = ({ ladCode }) => {
	const { unemploymentRatesByLadCode: { labels = [], datasets = [] } = {} } =
		useLazyLoadQuery<UnemploymentRatesResponse>(UnemploymentRatesRequest, { ladCode })

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodesRequest, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}

	return <UnemploymentRateChart model={model} />
}

export { LadUnemploymentRateChart }
