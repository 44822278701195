import React from 'react'

import { LineChart } from '../../shared/charts/LineChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface HouseholdsWithUniversalCreditWorkingPopulationRatiopRatioProps {
	model: Model
}

const HouseholdsWithUniversalCreditWorkingPopulationRatio: React.FC<
	HouseholdsWithUniversalCreditWorkingPopulationRatiopRatioProps
> = ({ model }) => (
	<>
		<Subtitle>Universal Credit Households to Working Population Ratio</Subtitle>
		<Paragraph>Individuals on Universal Credit as a share of the working age population (16-64) over time</Paragraph>
		<VerticalSpace size="sm" />
		<LineChart model={model} prefix="" suffix="%" dataLabelDisplayOption="showAll" aspectRatio={1} />
	</>
)

export { HouseholdsWithUniversalCreditWorkingPopulationRatio }
