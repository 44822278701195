import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { AAAwardTypesByCaQuery as QueryResponseType } from '../../graphql/queries/__generated__/AAAwardTypesByCaQuery.graphql'
import {
	RadiusValues,
	SitesByCaQuery as SitesQueryType,
} from '../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { AAAwardTypesByCaQuery as QueryRequestType } from '../../graphql/queries/AAAwardTypesByCa'
import { SitesByCaQuery as SitesQueryRequest } from '../../graphql/queries/SitesByCa'
import { Model } from '../../shared/charts/types'
import { ElderlyBedShortagesForecastChartSiteFragment$key } from './__generated__/ElderlyBedShortagesForecastChartSiteFragment.graphql'
import { ElderlyBedShortagesForecastChart } from './ElderlyBedShortagesForecastChart'
import { ElderlyBedShortagesForecastChartSiteFragment } from './ElderlyBedShortagesForecastChartSiteFragment'
import { getBedSupplyAndDemandHistoricalForecast } from './helpers'

const startYear = 2020
const endYear = 2032

interface CaChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaElderlyBedShortagesForecastChart: React.FC<CaChartProps> = ({ oaCode, radius }) => {
	// TODO: Fetch CA data
	const { aaAwardTypesByCa: aaData } = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		oaCode,
		radius,
	})

	const sitesData = useLazyLoadQuery<SitesQueryType>(SitesQueryRequest, {
		oaCode,
		radius,
	})

	const sites = sitesData.sitesByCa.map((site: ElderlyBedShortagesForecastChartSiteFragment$key) => {
		return useFragment(ElderlyBedShortagesForecastChartSiteFragment, site)
	})

	// Process data
	const model: Model = getBedSupplyAndDemandHistoricalForecast(aaData, sites, startYear, endYear)

	return <ElderlyBedShortagesForecastChart model={model} />
}

export { CaElderlyBedShortagesForecastChart }
