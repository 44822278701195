/**
 * @generated SignedSource<<941220429c7127b04a2d66cb5e99d884>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OperatorGroupsQuery$variables = Record<PropertyKey, never>;
export type OperatorGroupsQuery$data = {
  readonly operatorGroups: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly operators: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  }>;
};
export type OperatorGroupsQuery = {
  response: OperatorGroupsQuery$data;
  variables: OperatorGroupsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OperatorGroup",
    "kind": "LinkedField",
    "name": "operatorGroups",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "OperatorGroupListItem",
        "kind": "LinkedField",
        "name": "operators",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OperatorGroupsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OperatorGroupsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4cc5b412700b9809b329886cfadd5c8a",
    "id": null,
    "metadata": {},
    "name": "OperatorGroupsQuery",
    "operationKind": "query",
    "text": "query OperatorGroupsQuery {\n  operatorGroups {\n    id\n    name\n    operators {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "56d2daef86ec140f100286dddb0f4d81";

export default node;
