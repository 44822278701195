import React from 'react'

import { LadElderlyCareNeedPrevalenceRateChart } from '../../../../charts/ElderlyCareNeedPrevalenceRate/LadElderlyCareNeedPrevalenceRateChart'
import { LadElderlyCareNeedsChart } from '../../../../charts/ElderlyCareNeeds/LadElderlyCareNeedsChart'
import { LadElderlyPopulationIn10YearsChart } from '../../../../charts/ElderlyPopulationIn10Years/LadElderlyPopulationIn10YearsChart'
import { LadElderlyPopulationShareChart } from '../../../../charts/ElderlyPopulationShare/LadElderlyPopulationShareChart'
import { LeftAligned } from '../../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../../shared/layout/Space'
import { Loading } from '../../../../shared/Loading'
import { Panel } from '../../../../shared/Panel'
import { SubtitleBox } from '../../../../shared/Text'
import { LadAIElderlySummary } from './LadAIElderlySummary'

interface ElderlyProps {
	ladCode: string
}

const Elderly: React.FC<ElderlyProps> = ({ ladCode }) => {
	return (
		<Container>
			<VerticalSpace size="xl" />
			<Row>
				<LeftAligned>
					<HorizontalSpace size="md" />
					<Col count={7}>
						<SubtitleBox>Elderly Demographics Report</SubtitleBox>
						<LadAIElderlySummary ladCode={ladCode} />
					</Col>
				</LeftAligned>
			</Row>
			<VerticalSpace size="xl" />
			<Row>
				<Col count={6}>
					<Panel>
						<LadElderlyPopulationIn10YearsChart ladCode={ladCode} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<LadElderlyPopulationShareChart ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={6}>
					<Panel>
						<LadElderlyCareNeedsChart ladCode={ladCode} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<LadElderlyCareNeedPrevalenceRateChart ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<ElderlyProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Elderly ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as Elderly }
