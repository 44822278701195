import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery as GeographiesQueryType } from '../../../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { PopulationBracketsByLadCodeQuery as PopulationQueryType } from '../../../../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import { StatXDementiaAACountQuery as DementiaQueryType } from '../../../../graphql/queries/__generated__/StatXDementiaAACountQuery.graphql'
import { GeographiesByCodesQuery as GeographiesQuery } from '../../../../graphql/queries/GeographiesByCodes'
import { PopulationBracketsByLadCodeQuery } from '../../../../graphql/queries/PopulationBrackets'
import { StatXDementiaAACountQuery } from '../../../../graphql/queries/StatXDementiaAACount'
import { AIStructuredText } from '../../../../shared/Ai/AIStructuredText'
import { TextPlaceholder } from '../../../../shared/textPlaceholder/TextPlaceholder'
import { calculateElderlyWithDementiaShare } from '../../../../utils/calculateElderlyWithDementiaShare'
import { transformToKeyValue } from '../../../../utils/transformToKeyValue'

interface LadAIDementiaSummaryProps {
	ladCode: string
}

const LadAIDementiaSummary: React.FC<LadAIDementiaSummaryProps> = ({ ladCode }) => {
	// Fetch elderly population data

	const elderlyPopulationTimeSeries = useLazyLoadQuery<PopulationQueryType>(PopulationBracketsByLadCodeQuery, {
		ladCode: ladCode,
		bracket: '65-1000',
		yearsPast: 3,
		yearsFuture: 0,
	})

	const elderlyWithDementiaCount = useLazyLoadQuery<DementiaQueryType>(StatXDementiaAACountQuery, {
		geography: ladCode,
	})

	const elderlyDementiaPrevalenceRateData = calculateElderlyWithDementiaShare(
		elderlyPopulationTimeSeries,
		elderlyWithDementiaCount,
	)

	const elderlyDementiaPrevalenceRateKeyValue = transformToKeyValue(elderlyDementiaPrevalenceRateData)

	// Fetch geographies data
	const { geographiesByCodes: geos } = useLazyLoadQuery<GeographiesQueryType>(GeographiesQuery, {
		codes: [ladCode],
	})

	// Construct the prompt
	const prompt = `
You are a data analyst specializing in demographics, elderly care homes with a focus on elderly with dementia. Analyze the dementia trends of the catchment area (lad), based solely on the data provided below.

Data:
1. Geographies: ${JSON.stringify(geos)}
1. Count of elderly suffering of severe dementia: ${JSON.stringify(elderlyWithDementiaCount)}
2. Dementia Prevalence Rate for the catchment area, the region and England (the share of elderly that suffer from severe dementia, data provides historic time series from 2021 until 2024 i.e. 2024 ist the current rate): ${JSON.stringify(elderlyDementiaPrevalenceRateKeyValue)}
Be aware that we are using . for decimal points and that the values are alreay %. Compare the trends over the years and in particular compare the rate for the current year of the catchment area, the Region and England, be superthorough in your analysis in regards to analysing if a value is lower or higher than another value!

Instructions:
Start directly with a concise headline (maximum 30 characters) summarizing the main finding. Do not include any labels, introductory words or quotes.

Follow with a paragraph of 90-150 characters that accurately reflects your analysis. Do not include any labels or introductory words.

Focus on Accurate Data Analysis:

Base all interpretations and conclusions directly on the data provided.

Double-check numerical values when making comparisons. 

Do not make assumptions beyond the given data.
`

	const iteration = 24
	const key = `dementia-summary-${ladCode}-${iteration}`

	return <AIStructuredText prompt={prompt} cacheKey={key} />
}

const SuspendedLadAIDementiaSummary: React.FC<LadAIDementiaSummaryProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<TextPlaceholder />}>
			<LadAIDementiaSummary ladCode={ladCode} />
		</React.Suspense>
	)
}

export { SuspendedLadAIDementiaSummary as LadAIDementiaSummary }
