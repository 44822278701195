import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import {
	PopulationByCaQuery as Response,
	RadiusValues,
} from '../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { PopulationByCaQuery as Request } from '../../graphql/queries/PopulationByCa'
import { Loading } from '../../shared/Loading'
import { formatNumber } from '../../utils/formatNumber'
import { QuickFact } from '../QuickFacts'

interface PopulationQuickFactProps {
	oaCode: string
	radius: RadiusValues
}

const PopulationByCa: React.FC<PopulationQuickFactProps> = ({ oaCode, radius }) => {
	const { populationByCa: pop } = useLazyLoadQuery<Response>(Request, {
		oaCode,
		radius,
		startYear: currentYear,
		futureOffset: 0,
		minAge: 0,
		rangeSize: 100,
		includeIntermediates: false,
	})

	const lad = pop.geographies[0].ageRanges[0].values[0]
	const national = pop.geographies[2].ageRanges[0].values[0]

	return (
		<QuickFact heading="Population" value={formatNumber(lad)} comparison={formatNumber(national)} isComparable={true} />
	)
}

const Suspended: React.FC<PopulationQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading height={75} />}>
			<PopulationByCa {...props} />
		</React.Suspense>
	)
}

export { Suspended as PopulationByCa }
