import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { SitesByLadCodeQuery as SitesResponse } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { SitesByLadCodeQuery as SitesRequest } from '../../graphql/queries/SitesByLadCode'
import { SitesTableFragment$key } from './__generated__/SitesTableFragment.graphql'
import { SitesTable } from './SitesTable'
import { SitesTableFragment } from './SitesTableFragment'

interface SitesTableProps {
	ladCode: string
}

const LaSitesTable: React.FC<SitesTableProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<SitesResponse>(SitesRequest, { ladCode })

	const sitesData = React.useMemo(() => {
		return data.sitesByLadCode || []
	}, [data])

	// Use useFragment on each item in the array
	const sites = sitesData.map((site: SitesTableFragment$key) => {
		return useFragment(SitesTableFragment, site)
	})

	return <SitesTable sites={sites} />
}

export { LaSitesTable }
