import React from 'react'

type TextProps = {
	children: React.ReactNode
	className?: string
}

const PageTitle: React.FC<TextProps> = ({ children, className }) => (
	<h1 style={{ marginTop: 20, marginBottom: 20 }} className={`text-4xl ${className}`}>
		{children}
	</h1>
)

const Title: React.FC<TextProps> = ({ children, className }) => <h2 className={` ${className}`}>{children}</h2>

const Subtitle: React.FC<TextProps> = ({ children, className }) => <h5 className={` ${className}`}>{children}</h5>

type HeadingProps = TextProps & {
	level: 1 | 2 | 3 | 4 | 5 | 6
	color?: string
}

const Heading: React.FC<HeadingProps> = ({ children, className, level, color }) => {
	const Tag = `h${level}` as keyof JSX.IntrinsicElements
	return (
		<Tag className={className} style={{ color }}>
			{children}
		</Tag>
	)
}

const Paragraph: React.FC<TextProps> = ({ children, className = '' }) => (
	<p style={{ margin: 0 }} className={`${className}`}>
		{children}
	</p>
)

type TextBoxProps = {
	children: React.ReactNode
	color?: string
}

const TitleBox: React.FC<TextBoxProps> = ({ children, color }) => <h5 style={{ color }}>{children}</h5>

const ColoredBold: React.FC<TextBoxProps> = ({ children, color }) => <h5 style={{ color }}>{children}</h5>

const SubtitleBox: React.FC<TextBoxProps> = ({ children }) => (
	<h6 className="text-primary" style={{ margin: '0 0 0.75rem 0', textTransform: 'uppercase', letterSpacing: 1 }}>
		{children}
	</h6>
)

const ParagraphBold: React.FC<TextBoxProps> = ({ children, color }) => (
	<p style={{ margin: '0 0 0.5rem 0', color }} className="fw-bold">
		{children}
	</p>
)

const ParagraphLight: React.FC<TextProps> = ({ children }) => <Paragraph className="fw-light">{children}</Paragraph>

const ParagraphSmall: React.FC<TextBoxProps> = ({ children }) => <Paragraph className="fs-5">{children}</Paragraph>

const ParagraphSmallBold: React.FC<TextBoxProps> = ({ children }) => <p className="fs-6 fw-bold">{children}</p>

export {
	ColoredBold,
	Heading,
	PageTitle,
	Paragraph,
	ParagraphBold,
	ParagraphLight,
	ParagraphSmall,
	ParagraphSmallBold,
	Subtitle,
	SubtitleBox,
	Title,
	TitleBox,
}
