import React, { useEffect } from 'react'

interface PageProps {
	title: string
	children: React.ReactNode
}

function Page({ title, children }: PageProps) {
	useEffect(() => {
		document.title = 'Propmatch - ' + title
	}, [title])

	return <>{children}</>
}

export { Page }
