import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { ElderlyCareStatsByLadCodeQuery as EcsResponseType } from '../graphql/queries/__generated__/ElderlyCareStatsByLadCodeQuery.graphql'
import { HouseholdIncomeByLadCodeQuery as GDHIResponseQuery } from '../graphql/queries/__generated__/HouseholdIncomeByLadCodeQuery.graphql'
import { HousePriceIndexByLadCodeQuery as HPIResponseQuery } from '../graphql/queries/__generated__/HousePriceIndexByLadCodeQuery.graphql'
import { MedianAgeByLadCodeQuery as AgeResponseQuery } from '../graphql/queries/__generated__/MedianAgeByLadCodeQuery.graphql'
import { PopulationBracketsByLadCodeQuery as PopResponseQuery } from '../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import { UnemploymentRatesByLadCodeQuery as UnemploymentRatesResponse } from '../graphql/queries/__generated__/UnemploymentRatesByLadCodeQuery.graphql'
import { ElderlyCareStatsByLadCodeQuery as EcsRequestType } from '../graphql/queries/ElderlyCareStatsByLad'
import { HouseholdIncomeByLadCodeQuery as GHDIRequestQuery } from '../graphql/queries/HouseholdIncomeByLad'
import { HousePriceIndexByLadCodeQuery as HPIRequestQuery } from '../graphql/queries/HousePriceIndexByLad'
import { MedianAgeByLadCodeQuery as AgeRequestQuery } from '../graphql/queries/MedianAgeByLad'
import { PopulationBracketsByLadCodeQuery as PopRequestQuery } from '../graphql/queries/PopulationBrackets'
import { UnemploymentRatesByLadCodeQuery as UnemploymentRatesRequest } from '../graphql/queries/UnemploymentRatesByLad'
import { theme } from '../Theme'
import { formatNumber } from '../utils/formatNumber'
import { Col, Container, Row } from './layout/Grid'
import { VerticalSpace } from './layout/Space'
import { Loading } from './Loading'
import { Panel } from './Panel'
import { Heading } from './Text'

interface QuickFactsProps {
	ladCode: string
}

const QuickFacts: React.FC<QuickFactsProps> = ({ ladCode }) => {
	const { populationBracketsByLadCode: pop } = useLazyLoadQuery<PopResponseQuery>(PopRequestQuery, {
		ladCode,
		bracket: '0-100',
		yearsFuture: 1,
		yearsPast: 1,
	})

	const { elderlyCareStatsByLadCode: ecs } = useLazyLoadQuery<EcsResponseType>(EcsRequestType, {
		ladCode,
	})

	const { medianAgeByLadCode: age } = useLazyLoadQuery<AgeResponseQuery>(AgeRequestQuery, {
		ladCode,
		years: 1,
	})

	const { householdIncomeByLadCode: gdhi } = useLazyLoadQuery<GDHIResponseQuery>(GHDIRequestQuery, {
		ladCode,
	})

	const { unemploymentRatesByLadCode: uer } = useLazyLoadQuery<UnemploymentRatesResponse>(UnemploymentRatesRequest, {
		ladCode,
	})

	const { housePriceIndexByLadCode: hpi } = useLazyLoadQuery<HPIResponseQuery>(HPIRequestQuery, {
		ladCode,
	})

	const nationalGDHI = '£ ' + formatNumber(22213)
	const nationalElderlyShare = formatNumber(19.1) + '%'
	const nationalHouseAvg = '£ ' + formatNumber(308605)

	const gdhiMostRecent = gdhi.years.reduce((mostRecent, current) => {
		return current.year > mostRecent.year ? current : mostRecent
	}, gdhi.years[0])
	const ladPop = pop.years[1].geographies[0].total
	const nationalPop = pop.years[1].geographies[2].total
	const ladHousePrice = hpi.ladYears[hpi.ladYears.length - 1].averagePrice
	const uerLad = uer.datasets[0].values[uer.datasets[0].values.length - 1]
	const uerCountry = uer.datasets[uer.datasets.length - 1].values[uer.datasets[0].values.length - 1]

	const popLad = formatNumber(ladPop)
	const popComparison = formatNumber(nationalPop)
	const elderlyPopShare = formatNumber((ecs.populationOver65 / ladPop) * 100) + '%'
	const ladAge = formatNumber(age.ladAge)
	const nationalAge = formatNumber(age.nationalAge)
	const gdhiLad = '£ ' + formatNumber(gdhiMostRecent.perHead)
	const ladEmp = formatNumber(uerLad || 0) + '%'
	const nationalEmp = formatNumber(uerCountry || 0) + '%'
	const ladAgHousePrice = '£ ' + formatNumber(ladHousePrice || 0)

	return (
		<Container removePadding>
			<Row>
				<Col count={6}>
					<QuickFact heading="Population" value={popLad} comparison={popComparison} isComparable={false} />
				</Col>
				<Col count={6}>
					<QuickFact heading="Elderly Population Share" value={elderlyPopShare} comparison={nationalElderlyShare} />
				</Col>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<Col count={6}>
					<QuickFact heading="Median Age" value={ladAge} comparison={nationalAge} />
				</Col>
				<Col count={6}>
					<QuickFact heading="Disposable Household Income" value={gdhiLad} comparison={nationalGDHI} />
				</Col>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<Col count={6}>
					<QuickFact heading="House Price Average" value={ladAgHousePrice} comparison={nationalHouseAvg} />
				</Col>
				<Col count={6}>
					<QuickFact heading="Unemployment Rate" value={ladEmp} comparison={nationalEmp} />
				</Col>
			</Row>
		</Container>
	)
}

interface QuickFactProps {
	heading: string
	value: string
	comparison: string
	isComparable?: boolean
}

const QuickFact: React.FC<QuickFactProps> = ({ heading, value, comparison, isComparable = true }) => {
	const color = isComparable ? (value > comparison ? theme.colors.primary : theme.colors.red) : theme.colors.darkText

	return (
		<Panel padding={32}>
			<Heading level={6}>{heading}</Heading>
			<Heading level={3} color={color}>
				{value}
			</Heading>
			<Heading level={6} color={theme.colors.grey}>
				{comparison}
			</Heading>
		</Panel>
	)
}

const Suspended: React.FC<QuickFactsProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<QuickFacts ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as LadElderlyCareQuickFacts }
