import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { AAUniversalCreditPopulationRatiosByLadQuery as AAUniversalCreditsByLadCodeResponse } from '../../graphql/queries/__generated__/AAUniversalCreditPopulationRatiosByLadQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { AAUniversalCreditPopulationRatiosByLadQuery as AAUniversalCreditsByLadCodeRequest } from '../../graphql/queries/AAUniversalCreditPopulationRatiosByLad'
import { GeographiesByCodesQuery as GeographiesByCodesRequest } from '../../graphql/queries/GeographiesByCodes'
import { Model } from '../../shared/charts/types'
import { HouseholdsWithUniversalCreditWorkingPopulationRatio } from './HouseholdsWithUniversalCreditWorkingPopulationChart'

interface LadUniversalCreditWorkingPopChartProps {
	ladCode: string
}

const LadHouseholdsWithUniversalCreditWorkingPopulationChart: React.FC<LadUniversalCreditWorkingPopChartProps> = ({
	ladCode,
}) => {
	const { aaUniversalCreditPopulationRatiosByLadCode: { labels = [], datasets = [] } = {} } =
		useLazyLoadQuery<AAUniversalCreditsByLadCodeResponse>(AAUniversalCreditsByLadCodeRequest, { ladCode })

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodesRequest, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}

	return <HouseholdsWithUniversalCreditWorkingPopulationRatio model={model} />
}

export { LadHouseholdsWithUniversalCreditWorkingPopulationChart }
