import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import {
	OperatorsByCaQuery as OperatorsResponse,
	RadiusValues,
} from '../../graphql/queries/__generated__/OperatorsByCaQuery.graphql'
import { OperatorsByCaQuery as OperatorsRequest } from '../../graphql/queries/OperatorsByCa'
import { OperatorsTableFragment$key } from './__generated__/OperatorsTableFragment.graphql'
import { OperatorsTable } from './OperatorsTable'
import { OperatorsTableFragment } from './OperatorsTableFragment'

interface CaOperatorsTableProps {
	oaCode: string
	radius: RadiusValues
}

const CaOperatorsTable: React.FC<CaOperatorsTableProps> = ({ oaCode, radius }) => {
	const data = useLazyLoadQuery<OperatorsResponse>(OperatorsRequest, { oaCode, radius })

	const operatorData = data.operatorsByCa || []

	const operators = operatorData.map((o: OperatorsTableFragment$key) => {
		return useFragment(OperatorsTableFragment, o)
	})

	return <OperatorsTable operators={operators} code={oaCode} name="Catchment Area" />
}

export { CaOperatorsTable }
