import * as turf from '@turf/turf'
import { Feature, MultiPolygon, Polygon } from 'geojson'
import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { LocalAuthorityByLadCodeQuery as LaQueryResponseType } from '../../../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { SitesByLadCodeQuery as SitesQueryResponseType } from '../../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { LocalAuthorityByLadCodeQuery as LaQueryRequestType } from '../../../graphql/queries/LocalAuthorityByLad'
import { SitesByLadCodeQuery as SitesQueryRequestType } from '../../../graphql/queries/SitesByLadCode'
import { LadQuickFacts } from '../../../quickFacts/LadQuickFacts'
import { Col, Row } from '../../../shared/layout/Grid'
import { VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { MapFeatures, Marker } from '../../../shared/map/MapFeatures'
import { Panel } from '../../../shared/Panel'
import { PageTitle, ParagraphBold, ParagraphLight, SubtitleBox } from '../../../shared/Text'
import { ReportSitesByLadQueryFragment$key } from './__generated__/ReportSitesByLadQueryFragment.graphql'
import { ReportSitesByLadQueryFragment } from './ReportSitesByLad'

interface SummaryProps {
	ladCode: string
}

const Summary: React.FC<SummaryProps> = ({ ladCode }) => {
	const { localAuthorityByLadCode: la } = useLazyLoadQuery<LaQueryResponseType>(LaQueryRequestType, { ladCode })
	const { sitesByLadCode: si } = useLazyLoadQuery<SitesQueryResponseType>(SitesQueryRequestType, { ladCode })

	const { geometry, name } = la

	const locations: Marker[] = si.map((site: ReportSitesByLadQueryFragment$key) => {
		const siteData = useFragment(ReportSitesByLadQueryFragment, site)
		return {
			location: JSON.parse(siteData.location!),
			name: siteData.name,
			id: siteData.id,
		}
	})

	const feature: Feature<Polygon | MultiPolygon> = {
		type: 'Feature',
		geometry: JSON.parse(geometry),
		properties: {},
	}

	const featureCollection = turf.featureCollection([feature])
	const featureCollections = [featureCollection]

	return (
		<Row>
			<Col count={6}>
				<Panel>
					<VerticalSpace size="lg" />
					<SubtitleBox>Elderly Care Market Analysis</SubtitleBox>
					<PageTitle> {name} </PageTitle>
					<ParagraphBold>
						Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
						dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
						diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
					</ParagraphBold>
					<ParagraphLight>
						Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
						dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
						diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum
						dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
						magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
						nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
					</ParagraphLight>
					<VerticalSpace size="lg" />
				</Panel>
			</Col>
			<Col count={6}>
				<Panel padding={16}>
					<MapFeatures features={featureCollections} locations={locations} />
				</Panel>
			</Col>
			<VerticalSpace size="sm" />
			<Row>
				<Col size="xl">
					<Panel>
						<LadQuickFacts ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
		</Row>
	)
}

const Suspended: React.FC<SummaryProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Summary ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as Summary }
