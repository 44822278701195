import React from 'react'

import { CaGDHIChart } from '../../../charts/GDHIChart/CaGDHIChart'
import { CaHourlyWagesChart } from '../../../charts/HourlyWagesChart/CaHourlyWagesChart'
import { CaHouseholdsWithUniversalCreditChart } from '../../../charts/HouseholdsWithUniversalCreditChart/CaHouseholdsWithUniversalCreditChart'
import { CaHouseholdsWithUniversalCreditWorkingPopulationChart } from '../../../charts/HouseholdsWithUniversalCreditWorkingPopulationChart/CaHouseholdsWithUniversalCreditWorkingPopulationChart'
import { CaIndustryDistributionChart } from '../../../charts/IndustryDistributionChart/CaIndustryDistributionChart'
import { CaUnemploymentRateChart } from '../../../charts/UnemploymentRateChart/CaUnemploymentRateChart'
import { RadiusValues } from '../../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByCaQuery.graphql'
import { LeftAligned } from '../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../shared/layout/Space'
import { Panel } from '../../../shared/Panel'
import { Paragraph, SubtitleBox } from '../../../shared/Text'

interface EconomicProps {
	oaCode: string
	radius: RadiusValues
}

const Economic: React.FC<EconomicProps> = ({ oaCode, radius }) => {
	return (
		<Container>
			<VerticalSpace size="xl" />
			<Row>
				<LeftAligned>
					<HorizontalSpace size="md" />
					<Col count={7}>
						<SubtitleBox>Economic Report</SubtitleBox>
						<Paragraph>Gives an economic report of the situation</Paragraph>
					</Col>
				</LeftAligned>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={12}>
					<Panel>
						<CaGDHIChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />

			<Row>
				<Col count={6}>
					<Panel>
						<CaHourlyWagesChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<CaUnemploymentRateChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={6}>
					<Panel>
						<CaHouseholdsWithUniversalCreditChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<CaHouseholdsWithUniversalCreditWorkingPopulationChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={12}>
					<Panel>
						<CaIndustryDistributionChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

export { Economic }
