import React from 'react'

import { LineChart } from '../../shared/charts/LineChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { ParagraphSmallBold, TitleBox } from '../../shared/Text'

interface ChartProps {
	model: Model
}

const ElderlyBedShortagesForecastChart: React.FC<ChartProps> = ({ model }) => {
	return (
		<>
			<TitleBox>Elderly Bed Supply and Demand Forecast</TitleBox>
			<ParagraphSmallBold>
				Regression analysis to forecast based on historic demand (Attendance Allowance Rate) and histroic supply data.
			</ParagraphSmallBold>
			<VerticalSpace size="sm" />
			<LineChart
				model={model}
				aspectRatio={2}
				dataLabelDisplayOption="showForSelectedLabels"
				selectedLabels={['2020', '2024', 'E2028', 'E2032']}
			/>
		</>
	)
}

export { ElderlyBedShortagesForecastChart }
