import graphql from 'babel-plugin-relay/macro'

export const SitesByCaQuery = graphql`
	query SitesByCaQuery($oaCode: String!, $radius: RadiusValues!) {
		sitesByCa(oaCode: $oaCode, radius: $radius) {
			...CareHomesSitesByCaQueryFragment
			...SitesTableFragment
			...ElderlySitesChartSiteFragment
			...ElderlyBedShortagesForecastChartSiteFragment
			...ElderlyBedsTimeSeriesChartSiteFragment
		}
	}
`
