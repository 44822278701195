/**
 * @generated SignedSource<<b4dd70e53229aa3c6df513487f29ba5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CareHomesSitesByCaQueryFragment$data = {
  readonly id: any;
  readonly location: string | null | undefined;
  readonly name: string;
  readonly " $fragmentType": "CareHomesSitesByCaQueryFragment";
};
export type CareHomesSitesByCaQueryFragment$key = {
  readonly " $data"?: CareHomesSitesByCaQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CareHomesSitesByCaQueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CareHomesSitesByCaQueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "6c1b0abdd99b99bc0ea5343f57f9c0f9";

export default node;
