import { formatPercentage } from './formatNumber'

export function calculateCAGR(startValue: number, endValue: number, numberOfYears: number): number {
	if (startValue <= 0 || endValue <= 0 || numberOfYears <= 0) {
		return NaN
	}
	return Math.pow(endValue / startValue, 1 / numberOfYears) - 1
}

export function calculate3YearCAGR(startValue: number, endValue: number): number {
	return calculateCAGR(startValue, endValue, 3)
}

export function CAGRDisplay(startValue: number, endValue: number): string {
	if (startValue === 0 && endValue > 0) {
		return 'New'
	} else if (startValue > 0 && endValue > 0) {
		const cagr = calculate3YearCAGR(startValue, endValue)
		if (!isNaN(cagr)) {
			return formatPercentage(cagr * 100, 0, 1)
		}
	}
	return 'NA'
}
