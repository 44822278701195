import React from 'react'

import { BarChart } from '../../shared/charts/BarChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { ParagraphSmallBold, TitleBox } from '../../shared/Text'

interface ElderlyBedsTimeSeriesChartProps {
	model: Model
}

const ElderlyBedsTimeSeriesChart: React.FC<ElderlyBedsTimeSeriesChartProps> = ({ model }) => {
	return (
		<>
			<TitleBox>Elderly Care Beds</TitleBox>
			<ParagraphSmallBold>Beds in Care Homes for +65 age band</ParagraphSmallBold>
			<VerticalSpace size="sm" />
			<BarChart horizontal={false} stacked={false} model={model} showLabels={true} alpha={1} aspectRatio={2} />
		</>
	)
}

export { ElderlyBedsTimeSeriesChart }
