import React from 'react'

import { LineChart } from '../../shared/charts/LineChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface ElderlyCareNeedPrevalenceRateChartProps {
	model: Model
}

const ElderlyCareNeedPrevalenceRateChart: React.FC<ElderlyCareNeedPrevalenceRateChartProps> = ({ model }) => {
	return (
		<>
			<Subtitle>Care Need Prevalence Rate</Subtitle>
			<Paragraph>
				Share of elderly with severe disabilities that need care assistance | Attendance Allowance | Based on the newest
				data from the Department for Work and Pensions
			</Paragraph>
			<VerticalSpace size="sm" />
			<LineChart model={model} prefix="" suffix="%" dataLabelDisplayOption="showAll" aspectRatio={1} />
		</>
	)
}
export { ElderlyCareNeedPrevalenceRateChart }
