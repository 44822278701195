import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { ElderlyCareStatsByLadCodeQuery as EcsResponseType } from '../graphql/queries/__generated__/ElderlyCareStatsByLadCodeQuery.graphql'
import { HouseholdIncomeByLadCodeQuery as GDHIResponseQuery } from '../graphql/queries/__generated__/HouseholdIncomeByLadCodeQuery.graphql'
import { HousePriceIndexByLadCodeQuery as HPIResponseQuery } from '../graphql/queries/__generated__/HousePriceIndexByLadCodeQuery.graphql'
import { LocalAuthorityByLadCodeQuery as LaQueryResponseType } from '../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { PopulationBracketsByLadCodeQuery as PopResponseQuery } from '../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import { ElderlyCareStatsByLadCodeQuery as EcsRequestType } from '../graphql/queries/ElderlyCareStatsByLad'
import { HouseholdIncomeByLadCodeQuery as GHDIRequestQuery } from '../graphql/queries/HouseholdIncomeByLad'
import { HousePriceIndexByLadCodeQuery as HPIRequestQuery } from '../graphql/queries/HousePriceIndexByLad'
import { LocalAuthorityByLadCodeQuery as LaQueryRequestType } from '../graphql/queries/LocalAuthorityByLad'
import { PopulationBracketsByLadCodeQuery as PopRequestQuery } from '../graphql/queries/PopulationBrackets'
import { Col, Container, Row } from '../shared/layout/Grid'
import { VerticalSpace } from '../shared/layout/Space'
import { Loading } from '../shared/Loading'
import { Panel } from '../shared/Panel'
import { ColoredBold, Heading } from '../shared/Text'
import { theme } from '../Theme'
import { formatCurrency, formatPercentage } from '../utils/formatNumber'
import { CareFeeByLad } from './careFeesByLad/CareFeesByLad'
import { PopulationByLad } from './populationByLad/PopulationByLad'
import { QuickFacts, QuickFactsProps } from './QuickFacts'
import { UnemploymentRatesByLad } from './unemploymentRatesByLad/UnemploymentRatesByLad'

interface LadQuickFactsProps {
	ladCode: string
}

const LadQuickFacts: React.FC<LadQuickFactsProps> = ({ ladCode }) => {
	const { localAuthorityByLadCode: la } = useLazyLoadQuery<LaQueryResponseType>(LaQueryRequestType, { ladCode })

	const { populationBracketsByLadCode: pop } = useLazyLoadQuery<PopResponseQuery>(PopRequestQuery, {
		ladCode,
		bracket: '0-100',
		yearsFuture: 1,
		yearsPast: 1,
	})

	const { elderlyCareStatsByLadCode: ecs } = useLazyLoadQuery<EcsResponseType>(EcsRequestType, {
		ladCode,
	})

	const { householdIncomeByLadCode: gdhi } = useLazyLoadQuery<GDHIResponseQuery>(GHDIRequestQuery, {
		ladCode,
	})

	const { housePriceIndexByLadCode: hpi } = useLazyLoadQuery<HPIResponseQuery>(HPIRequestQuery, {
		ladCode,
	})

	const gdhiMostRecent = gdhi.years.reduce(
		(mostRecent, current) => (current.year > mostRecent.year ? current : mostRecent),
		gdhi.years[0],
	)
	const ladPop = pop.years[1].geographies[0].total
	const ladHousePrice = hpi.ladYears[hpi.ladYears.length - 1].averagePrice || 0

	const title = la.name
	const subTitle = `${la.regionName}, ${la.countryName}`

	const props: QuickFactsProps = {
		avgHousePrice: formatCurrency(ladHousePrice),
		elderlyPopulationShare: formatPercentage((ecs.populationOver65 / ladPop) * 100),
		gdhi: formatCurrency(gdhiMostRecent.perHead),
		nationalAvgHousePrice: formatCurrency(308605),
		nationalElderlyPopulationShare: formatPercentage(19.1),
		nationalGdhi: formatCurrency(22213),
	}

	return (
		<>
			<Container removePadding>
				<Row>
					<Col count={12}>
						<Panel padding={32}>
							<Heading level={1}>{title}</Heading>
							<ColoredBold color={theme.colors.grey}>{`${subTitle}`} </ColoredBold>
						</Panel>
					</Col>
				</Row>
				<VerticalSpace size="sm" />
				<Row>
					<Col count={6}>
						<CareFeeByLad ladCode={ladCode} />
					</Col>
					<Col count={6}>
						<PopulationByLad ladCode={ladCode} />
					</Col>
				</Row>
				<VerticalSpace size="sm" />
				<Row>
					<Col count={6}>
						<UnemploymentRatesByLad ladCode={ladCode} />
					</Col>
				</Row>
			</Container>
			<VerticalSpace size="sm" />
			<QuickFacts {...props} />
		</>
	)
}

const Suspended: React.FC<LadQuickFactsProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<LadQuickFacts ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as LadQuickFacts }
