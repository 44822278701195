import React from 'react'

import { CaDementiaPrevalenceRateChart } from '../../../charts/DementiaPrevalenceRate/CaDementiaPrevalenceRateChart'
import { CaElderlyWithSevereDementiaChart } from '../../../charts/ElderlyWithSevereDementia/CaElderlyWithSevereDementiaChart'
import { RadiusValues } from '../../../graphql/queries/__generated__/AADisablingConditionsByCaQuery.graphql'
import { LeftAligned } from '../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { SubtitleBox } from '../../../shared/Text'

interface DementiaProps {
	oaCode: string
	radius: RadiusValues
}

const Dementia: React.FC<DementiaProps> = ({ oaCode, radius }) => {
	return (
		<Container>
			<VerticalSpace size="xl" />
			<Row>
				<LeftAligned>
					<HorizontalSpace size="md" />
					<Col count={7}>
						<SubtitleBox>Elderly with Dementia Report</SubtitleBox>
					</Col>
				</LeftAligned>
			</Row>
			<VerticalSpace size="xl" />
			<Row>
				<Col count={6}>
					<Panel>
						<CaElderlyWithSevereDementiaChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<CaDementiaPrevalenceRateChart oaCode={oaCode} radius={radius} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<DementiaProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Dementia {...props} />
		</React.Suspense>
	)
}

export { Suspended as Dementia }
