import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { SocialGradesByLadCodeQuery as QueryResponseType } from '../../graphql/queries/__generated__/SocialGradesByLadCodeQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesRequest } from '../../graphql/queries/GeographiesByCodes'
import { SocialGradesByLadCodeQuery } from '../../graphql/queries/SocialGradesByLad'
import { Model } from '../../shared/charts/types'
import { SocialGradeChart } from './SocialGradeChart'

interface LadSocialGradeChartProps {
	ladCode: string
}

const LadSocialGradeChart: React.FC<LadSocialGradeChartProps> = ({ ladCode }) => {
	const { socialGradesByLadCode: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<QueryResponseType>(
		SocialGradesByLadCodeQuery,
		{ ladCode },
	)

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodesRequest, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}

	return <SocialGradeChart model={model} />
}

export { LadSocialGradeChart }
