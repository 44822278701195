import { CellContext, flexRender } from '@tanstack/react-table'
import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import { ElderlyCareStatsQuery as EcsResponseType } from '../../graphql/queries/__generated__/ElderlyCareStatsQuery.graphql'
import { LocalAuthoritiesQuery as LaResponseType } from '../../graphql/queries/__generated__/LocalAuthoritiesQuery.graphql'
import { ElderlyCareStatsQuery as EcsRequestType } from '../../graphql/queries/ElderlyCareStats'
import { LocalAuthoritiesQuery as LaRequestType } from '../../graphql/queries/LocalAuthorities'
import { theme } from '../../Theme'
import { formatNumber } from '../../utils/formatNumber'
import { DataTable, RowData } from '../DataTable'
import { CenterAligned } from '../layout/Alignment'
import { Col, Row } from '../layout/Grid'
import { Loading } from '../Loading'
import { ProgressBar } from '../ProgressBar'
import { Paragraph } from '../Text'
import { LaTableElderlyCareStatsFragment$key } from './__generated__/LaTableElderlyCareStatsFragment.graphql'
import { LaTableFragment$key } from './__generated__/LaTableFragment.graphql'
import { LaTableElderlyCareStatsFragment } from './LaTableElderlyCareStatsFragment'
import { LaTableFragment } from './LaTableFragment'

// Define BedSupply labels and colors based on quintile
const quintileToBedSupply = ['Best', 'Good', 'Mid', 'Low', 'Worst']

const LaTable: React.FC = () => {
	const navigate = useNavigate()
	const { localAuthorities } = useLazyLoadQuery<LaResponseType>(LaRequestType, {})
	const { elderlyCareStats } = useLazyLoadQuery<EcsResponseType>(EcsRequestType, {})

	const lads = React.useMemo(() => {
		return localAuthorities || []
	}, [localAuthorities])

	const ecs = React.useMemo(() => {
		return elderlyCareStats || []
	}, [elderlyCareStats])

	let totalWithStats = 0

	const ecsFragments = ecs.map((stat: LaTableElderlyCareStatsFragment$key) =>
		useFragment(LaTableElderlyCareStatsFragment, stat),
	)

	const columns = React.useMemo(
		() => [
			// { Header: 'Id', accessor: 'ladCode' },
			{ Header: 'Name', accessorKey: 'name' },
			{ Header: 'Region', accessorKey: 'regionName' },
			{ Header: 'Population 65+', accessorKey: 'populationOver65' },
			{ Header: 'Care beds', accessorKey: 'numberOfBeds' },
			{ Header: 'Bed Supply Rate', accessorKey: 'bedSupplyRate' },
			{ Header: 'Supply Score', accessorKey: 'comparativeSupplyScore' },
		],
		[],
	)

	const rows: RowData[] = lads.map((la: LaTableFragment$key) => {
		const laFragment = useFragment(LaTableFragment, la)
		const stat = ecsFragments.find((ec) => ec.ladCode === laFragment.ladCode)

		const cell: any = {
			id: laFragment.ladCode,
			...laFragment,
		}

		cell.bedSupply = -1

		if (stat) {
			totalWithStats++
			const bedSupply = (stat.numberOfBeds / stat.populationOver65) * 100
			cell.numberOfBeds = formatNumber(stat.numberOfBeds)
			cell.populationOver65 = formatNumber(stat.populationOver65)
			cell.bedSupplyRate = bedSupply.toFixed(1) + '%'
			cell.bedSupply = bedSupply
		}

		return cell
	})

	// Sort rows by bedSupply in descending order
	rows.sort((a: any, b: any) => b.bedSupply - a.bedSupply)

	const quintileSize = Math.floor(totalWithStats / quintileToBedSupply.length)

	rows.forEach((cell: any, i: number) => {
		if (cell.bedSupply) {
			cell.comparativeSupplyScore = Math.floor(i / quintileSize)
		}
		return cell
	})

	rows.sort((a: any, b: any) => a.name.localeCompare(b.name))

	const handleRowClick = (id: string) => {
		navigate(`/lads/${id}`) // Navigate to the site detail page using the row's ID
	}

	const onCell = (cellContext: CellContext<RowData, unknown>) => {
		const cellValue = cellContext.getValue()

		if (cellContext.column.id === 'bedSupplyRate') {
			return bedSupplyCell(cellContext)
		} else if (cellContext.column.id === 'comparativeSupplyScore') {
			return comparativeSupplyScoreCell(cellContext)
		}

		return <Paragraph>{cellValue == null ? '-' : flexRender(cellContext.column.columnDef.cell, cellContext)}</Paragraph>
	}

	return <DataTable columns={columns} data={rows} onCell={onCell} onRowClick={handleRowClick} />
}

function bedSupplyCell(cellContext: CellContext<RowData, unknown>) {
	const cellValue = cellContext.getValue() // replaces cell.value
	if (cellValue == null) {
		return <Paragraph>-</Paragraph>
	}

	return (
		<>
			<Row>
				<Col count={3}>
					<Paragraph>{flexRender(cellContext.column.columnDef.cell, cellContext)}</Paragraph>
				</Col>
				<Col count={9}>
					<CenterAligned>
						<ProgressBar progress={Number(cellValue)} />
					</CenterAligned>
				</Col>
			</Row>
		</>
	)
}

function comparativeSupplyScoreCell(cellContext: CellContext<RowData, unknown>) {
	const value = cellContext.getValue() as number
	const color = theme.colors.severity[value]
	const label = quintileToBedSupply[value]

	return (
		<CenterAligned>
			<div className="badge" style={{ backgroundColor: color }}>
				{label}
			</div>
		</CenterAligned>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading />}>
			<LaTable />
		</React.Suspense>
	)
}

export { Suspended as LaTable }
