import React from 'react'

import { LadDementiaPrevalenceRateChart } from '../../../charts/DementiaPrevalenceRate/LadDementiaPrevalenceRateChart'
import { ElderlyDemandForecastChart } from '../../../charts/ElderlyDemandForecastChart'
import { LadElderlyPopulationIn10YearsChart } from '../../../charts/ElderlyPopulationIn10Years/LadElderlyPopulationIn10YearsChart'
import { DemandTrendQuickFacts } from '../../../shared/DemandTrendQuickFacts'
import { Col, Row } from '../../../shared/layout/Grid'
import { VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { ParagraphSmallBold, SubtitleBox, TitleBox } from '../../../shared/Text'

interface DemandTrendsProps {
	ladCode: string
}

const DemandTrends: React.FC<DemandTrendsProps> = ({ ladCode }) => {
	return (
		<>
			<Row>
				<div className="col-12">
					<Panel>
						<Row>
							<Col count={6}>
								<SubtitleBox>Demand Trends</SubtitleBox>
								<TitleBox>Urgent Strategies Needed for Rising Demand in Elderly Care</TitleBox>
								<ParagraphSmallBold>
									Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
									labore et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur
									sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
									diam voluptua.
								</ParagraphSmallBold>
							</Col>
							<div className="col-1"></div>
							<div className="col-4">
								<DemandTrendQuickFacts ladCode={ladCode} />
							</div>
						</Row>
					</Panel>
				</div>
			</Row>
			<VerticalSpace size="sm" />

			<VerticalSpace size="sm" />
			<Row>
				<div className="col-6">
					<Panel>
						<LadElderlyPopulationIn10YearsChart ladCode={ladCode} />
					</Panel>
				</div>
				<div className="col-6">
					<Panel>
						<ElderlyDemandForecastChart ladCode={ladCode} />
					</Panel>
				</div>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<div className="col-6">
					<Panel>
						<LadDementiaPrevalenceRateChart ladCode={ladCode} />
					</Panel>
				</div>
			</Row>

			<VerticalSpace size="lg" />
		</>
	)
}

const Suspended: React.FC<DemandTrendsProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<DemandTrends ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as DemandTrends }
