/**
 * @generated SignedSource<<d2a8152dea239e39ce415c2d27c3ca36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AAAwardTypesByLadCodeQuery$variables = {
  ladCode: string;
};
export type AAAwardTypesByLadCodeQuery$data = {
  readonly aaAwardTypesByLadCode: {
    readonly higherRate: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly labels: ReadonlyArray<string>;
    readonly lowerRate: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly total: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
  };
};
export type AAAwardTypesByLadCodeQuery = {
  response: AAAwardTypesByLadCodeQuery$data;
  variables: AAAwardTypesByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "values",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ladCode",
        "variableName": "ladCode"
      }
    ],
    "concreteType": "AAAwardTypes",
    "kind": "LinkedField",
    "name": "aaAwardTypesByLadCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labels",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AAAwardTypeData",
        "kind": "LinkedField",
        "name": "higherRate",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AAAwardTypeData",
        "kind": "LinkedField",
        "name": "lowerRate",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AAAwardTypeData",
        "kind": "LinkedField",
        "name": "total",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AAAwardTypesByLadCodeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AAAwardTypesByLadCodeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ed22049c79da4b356e7c3abb576f8d0d",
    "id": null,
    "metadata": {},
    "name": "AAAwardTypesByLadCodeQuery",
    "operationKind": "query",
    "text": "query AAAwardTypesByLadCodeQuery(\n  $ladCode: String!\n) {\n  aaAwardTypesByLadCode(ladCode: $ladCode) {\n    labels\n    higherRate {\n      code\n      values\n    }\n    lowerRate {\n      code\n      values\n    }\n    total {\n      code\n      values\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dececeb5a0c3b85ebe543dae7e24ed5e";

export default node;
