import React from 'react'

import { LineChart } from '../../shared/charts/LineChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface HourlyWagesChartProps {
	model: Model
}

const HourlyWagesChart: React.FC<HourlyWagesChartProps> = ({ model }) => (
	<>
		<Subtitle>Hourly Wage</Subtitle>
		<Paragraph>
			Median earnings in pounds for employees working in the catchment area | ONS Annual Survey of Hours and Earnings
		</Paragraph>
		<VerticalSpace size="md" />
		<LineChart model={model} prefix="£" dataLabelDisplayOption="showAll" aspectRatio={1} />
	</>
)

export { HourlyWagesChart }
