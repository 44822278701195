import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../Constants'
import { AAAwardTypesByLadCodeQuery as QueryResponseType } from '../../graphql/queries/__generated__/AAAwardTypesByLadCodeQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { PopulationByLadCodeQuery as PopResponse } from '../../graphql/queries/__generated__/PopulationByLadCodeQuery.graphql'
import { AAAwardTypesByLadCodeQuery as QueryRequestType } from '../../graphql/queries/AAAwardTypesByLad'
import { GeographiesByCodesQuery as GeographiesByCodesRequest } from '../../graphql/queries/GeographiesByCodes'
import { PopulationByLadCodeQuery } from '../../graphql/queries/PopulationByLad'
import { calculateElderlyWithCareNeedShare } from '../../utils/calculateElderlyWithCareNeedShare'
import { ElderlyCareNeedPrevalenceRateChart } from './ElderlyCareNeedPrevalenceRateChart'

interface LadElderlyCareNeedPrevalenceRateChartProps {
	ladCode: string
}

const LadElderlyCareNeedPrevalenceRateChart: React.FC<LadElderlyCareNeedPrevalenceRateChartProps> = ({ ladCode }) => {
	const { populationByLadCode: p } = useLazyLoadQuery<PopResponse>(PopulationByLadCodeQuery, {
		ladCode,
		startYear: currentYear - 5,
		futureOffset: 5,
		minAge: 65,
		rangeSize: 100,
		includeIntermediates: true,
	})

	const { aaAwardTypesByLadCode: aa } = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		ladCode,
	})

	const codes = p.geographies.map((d) => d.code)
	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodesRequest, {
		codes,
	})

	const model = calculateElderlyWithCareNeedShare(p, [...aa.higherRate], geos)

	return <ElderlyCareNeedPrevalenceRateChart model={model} />
}

export { LadElderlyCareNeedPrevalenceRateChart }
