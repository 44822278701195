import graphql from 'babel-plugin-relay/macro'

export const OperatorsOperatorFragment = graphql`
	fragment OperatorsOperatorFragment on Operator {
		id
		constituency
		name
		serviceTypes
		brandName
		brandId
		sites {
			id
			localAuthority
			name
			odsCode
			onspdLatitude
			onspdLongitude
			region
			registrationDate
			sector
			serviceTypes
			website
			currentRatings
			ladCode
			numberOfBeds
			region
			servicesProviders
			specialisms
		}
	}
`
