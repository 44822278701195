import graphql from 'babel-plugin-relay/macro'

export const UnemploymentRatesByLadCodeQuery = graphql`
	query UnemploymentRatesByLadCodeQuery($ladCode: String!) {
		unemploymentRatesByLadCode(ladCode: $ladCode) {
			labels
			datasets {
				code
				values
			}
		}
	}
`
